import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { findByProperty, prepareRequest } from '@proman/utils';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { UniqueValidatorService } from '@proman/services/unique-validator.service';
import { DynamicFieldsService } from '@proman/services/dynamic-fields.service';
import { EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';
import { ToastService } from '@proman/services/toast.service';
import { FilterService } from '@proman/services/filter.service';

@Component({
    selector: 'pm-entity-create-dialog',
    template: `
        <form #createForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="create(createForm)">
            <pro-dialog-title [title]="data.header || 'new'"></pro-dialog-title>
            <div mat-dialog-content>
                <pro-parameter *ngFor="let parameter of parameters"
                              [parameter]="parameter"
                              [config]="parameter.config"
                              [disabled]="parameter.config.disabled"
                              (onChange)="set(parameter.key, $event)">
                </pro-parameter>

                <pm-dynamic-fields
                        [entityName]="data.entity"
                        [isCreate]="true"
                        (createFieldsEmit)="handleDynamicFields($event)">
                </pm-dynamic-fields>
            </div>
            <pro-dialog-actions variant="create" [isCallbackDisabled]="disableSubmitCallback"></pro-dialog-actions>
        </form>
    `
})

export class EntityCreateDialogComponent {
    parameters: EntityItemFieldConfig[];
    form: UntypedFormGroup;
    controls: any = {};
    entityInstance: EntityInterface;
    disableSubmitCallback: boolean;
    dynamicFieldsValid: boolean;
    dynamicFieldsData: any[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private UniqueValidatorService: UniqueValidatorService,
        private DynamicFields: DynamicFieldsService,
        private Toast: ToastService,
        private Filter: FilterService,
        public dialogRef: MatLegacyDialogRef<EntityCreateDialogComponent>
    ) {
        // let control = (data.mainField && data.mainField.notUnique) ?
        //     new FormControl('', [Validators.required]) :
        //     new FormControl('', [Validators.required], this.UniqueValidatorService.get(this.data.entity, this.data.mainField && this.data.mainField.key));

        let mainField: EntityItemFieldConfig = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: Object.assign({ required: true }, this.data.mainField && this.data.mainField.config || {})
        };

        if (!mainField.notUnique) {
            mainField.config.validators = mainField.config.validators || {};
            mainField.config.validators.unique = { resource: data.entity, field: mainField.key };
        }

        this.entityInstance = Entity.get({ name: this.data.entity });

        this[this.data.entity] = {};

        this.parameters = [
            Object.assign({}, mainField, this.data.mainField || {})
        ];

        if (this.data.parameters) this.parameters = [].concat(this.parameters, this.data.parameters);

        for (let parameter of this.parameters) {

            if (parameter.config.control) this.controls[parameter.key] = parameter.config.control;

            // no form control for files
            // if (parameter.config.required && !this.controls[parameter.key] && !(parameter.type === 'files')) {
            //     let control = new FormControl(parameter.value || '', Validators.required);
            //     this.controls[parameter.key] = control;
            //     parameter.config.control = control;
            // }

            if (parameter.value) this.set(parameter.key, parameter.value);

            if (parameter.type === 'string') parameter.config = Object.assign({ debounce: 0 }, parameter.config);
            if (parameter.type === 'accounting') parameter.value = {};

        }

        this.form = new UntypedFormGroup(this.controls);
    }

    set(property: string, value: any) {
        const parameterConfig = findByProperty(this.parameters, 'name', property)?.config;
        if (parameterConfig && parameterConfig.associations) value = value.map((val: any) => val.id);
        if (parameterConfig && parameterConfig.groupInput) {
          Object.assign(this[this.data.entity], value )
        } else {
          this[this.data.entity][property] = value;
        }
    }

    create(createForm: any) {
      console.log(this[this.data.entity]);
        if (createForm.valid && this.isRequiredFilled() && this.dynamicFieldsValid) {

            let data = prepareRequest(this[this.data.entity]);

            if (this.data.params) data = Object.assign(data, this.data.params);

            this.disableSubmitCallback = true;

            if (this.data.postSuffix) {
                return this.entityInstance.postRequest(this.data.postSuffix, data)
                    .then((response: any) => this.functionA(response)).catch(() => this.disableSubmitCallback = false);

            }

            return this.entityInstance.create(data)
                .then((response: any) => this.functionA(response))
                .catch(() => {
                        this.disableSubmitCallback = false;
                    }
                );

        }

    }

    functionA(response: any) {
        const result = response.data || response;
        let requests: any = [];

        this.dynamicFieldsData.forEach((item: any) => {
            requests.push(this.DynamicFields.createFieldValue(Object.assign({ entityId: result }, item)));
        });

        return Promise.all(requests)
            .then(() => this.dialogRef.close(result))
            .catch(() => this.dialogRef.close(result));
    }

    isRequiredFilled() {
        for (let item of this.parameters) {
            if (item.config && item.config.required && !this[this.data.entity][item.key]) {
                let text = this.Filter.translate('missing_parameter');
                let paramName = this.Filter.translate(item.name);
                this.Toast.pop('error', `${text}: ${paramName}`);
                return false;
            }
        }

        return true;
    }

    handleDynamicFields(data: any) {
        this.dynamicFieldsValid = data.isValid;
        this.dynamicFieldsData = data.values;
    }
}
