import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[proImageError]'
})

export class ImageErrorDirective implements OnInit {
    @Input('proImageError') placeholder: string = '';

    constructor(
        private el: ElementRef,
    ) {

    }

    ngOnInit() {
        const placeholder = this.placeholder || 'assets/images/placeholder.png';
        const image: HTMLImageElement = this.el.nativeElement;

        const setPlaceholderCallback = () => {
            image.removeEventListener('error', setPlaceholderCallback);
            image.src = placeholder;
        };

        image.addEventListener('error', setPlaceholderCallback);
    }

}
