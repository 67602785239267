import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPreviewDialogComponent } from './dialogs/video-preview-dialog.component';
import { PromanCommonComponentsModule } from '../common-components/proman-common-components.module';
import { FilePreviewDialogComponent } from './dialogs/file-preview-dialog.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { EntityRepositionDialogComponent } from './dialogs/entity-reposition-dialog.component';
import { DragulaModule } from 'ng2-dragula';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { ConfirmActionDialogComponent } from '@proman/shared-dialogs/dialogs/confirm-action-dialog.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import {
  ParameterMaterialCategoryFilterSetupDialogComponent
} from '@proman/shared-dialogs/dialogs/article-material-category-filter-setup-dialog.component';
import {
  ParameterMaterialFilterSetupDialogComponent
} from '@proman/shared-dialogs/dialogs/article-material-filter-setup-dialog.component';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { PromanSelectComponent } from '@proman/select';

const COMPONENTS = [
  VideoPreviewDialogComponent,
  FilePreviewDialogComponent,
  EntityRepositionDialogComponent,
  ConfirmActionDialogComponent,
  ParameterMaterialFilterSetupDialogComponent, // parameters / parameters options / article materials /
  ParameterMaterialCategoryFilterSetupDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
      MatLegacyDialogModule,
    FlexLayoutModule,
    PipesModule,
    PromanCommonComponentsModule,
    PromanButtonComponent,
    PromanTextSimpleComponent,
    PromanSelectComponent,
    SharedDirectivesModule,
    DragulaModule.forRoot(),
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class PromanSharedDialogsModule {}
