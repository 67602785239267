import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '@proman/services/toast.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { Parameter } from '@proman/interfaces/entity-interfaces';
import { MentionConfig } from 'angular-mentions';
import { FilterService } from '@proman/services/filter.service';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { startCase } from 'lodash';

@Component({
    selector: 'pro-expression-edit-dialog',
    template: `
        <pro-dialog-title title="edit_function" [disableClose]="dialogRef.disableClose"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column" class="OverflowAuto">
            <textarea *ngIf="mentionConfig"
                      #textAreaElement
                      [(ngModel)]="expression"
                      (ngModelChange)="checkIfValid()"
                      [mentionConfig]="mentionConfig"
                      [cdkTextareaAutosize]="true"
                      (focus)="handleFocus()"
                      (keyup)="handleKeyUp($event)"></textarea>

            <pro-expression-humanize [value]="expression"></pro-expression-humanize>
            <pro-expression-format [value]="expression" (onChange)="expression = $event"></pro-expression-format>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>
    `,
    styles: [`
        textarea {
            border: none;
            border-bottom: 1px solid lightgrey;
            margin-bottom: 8px;
            padding: 4px;
            resize: both!important;
            overflow-y: auto;
        }
    `]
})

export class ExpressionEditDialogComponent implements OnInit {
    @ViewChild('textAreaElement') textAreaElement: ElementRef;
    expression: string;
    parameters: Parameter[];
    mentionConfig: MentionConfig;
    functions = {
        order: [
            'getQuantity()',
            'getName()',
            'getWeight()',
            'getInStock()',
            'getNumber()',
            'getId()',
            'getCustomerPrice()',
            'getCustomerPrice().getAmount()',
            'customerNumber',
            'desiredDispatchDate',
        ],
        orderProposal: [
            'getQuantity()',
            'getName()',
            'packagingQuantity',
        ],
        article: [
            'getName()',
            'type.name',
            'getId()',
        ],
        production: [
            'getId()',
            'getProductionQuantity()',
            'getName()',
            'getWeight()',
            'getInStock()',
            'getCustomerPrice().getAmount()',
            'getCustomerPrice()',
            'orderProduct.id',
        ],
        shipment: [
            'plannedQuantity',
            'storedQuantity',
            'preparedQuantity',
            'truckingNumber',
            'getName()',
        ],
        quant: [
            'getQuantity()',
            'getName()',
            'getInStock()',
            'get_material()',
            'get_material_category()',
            'getMaterialFormatValue()',
            'getWeight()',
        ],
        material: [
            'getQuantity()',
            'getName()',
            'getInStock()',
            'get_material()',
            'get_material_category()',
            'getMaterialFormatValue()',
        ],
        product: [
            'getPackagingQuantity()',
        ],
        purchaseProduct: [
            'getPackagingQuantity()',
        ],
        orderProduct: [
            'getPackagingQuantity()',
        ],
        productionProduct: [
            'getPackagingQuantity()',
            'getRequiredQuantity()',
        ],
        shipmentProduct: [
            'getPackagingQuantity()',
        ],
    };

    constructor(
        private ParametersOptions: ParametersOptionsService,
        private Toast: ToastService,
        private Filter: FilterService,
        private Render2: Renderer2,
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        public dialogRef: MatLegacyDialogRef<ExpressionEditDialogComponent>
    ) {
        this.expression = data.expression;
    }

    ngOnInit(): void {
        this.ParametersOptions
            .search({ entity: 'parameter' })
            .then((response: Parameter[]) => {
                this.parameters = response
                    .map((item) => {
                        item.name = `${item.name} - ${this.Filter.translate(item.context)}`;

                        return item;
                    });

                this.mentionConfig = {
                    mentions: [
                        {
                            items: this.parameters,
                            labelKey: 'name',
                            triggerChar: 'p',
                            mentionSelect:  (item: Parameter) => `p(${item.id})`,
                        },
                        {
                            items: [],
                            labelKey: 'name',
                            triggerChar: '.',
                            mentionSelect:  (item: SelectOption) => item.id.toString(),
                        },
                    ]
                }
            });
    }

    confirm() {
        this.dialogRef.close(this.expression);
    }

    checkIfValid() {
        const regex = new RegExp(/[^\wа-я *:/+\-()=&|_:?'\n".,~!<>\[\]{}%]+iu/);
        const value = this.expression;

        if (value.match(regex)) {
            this.Toast.pop('error', 'no_special_symbols_allowed');
        }

        this.expression = value.split(regex).join('');
    }

    handleKeyUp(event: KeyboardEvent) {
        const target = event.target as HTMLTextAreaElement;
        const pointer = target.selectionStart;

        const stringUntilPoint = this.expression.substring(0, pointer);

        let start = 0;
        const lastPoint = stringUntilPoint.lastIndexOf('.');
        const lastSpace = stringUntilPoint.lastIndexOf(' ');

        if (lastPoint > start) start = lastPoint + 1;
        if (lastSpace > start) start = lastSpace + 1;

        const lastWord = this.expression.substring(start, pointer);

        if (lastWord && this.functions[lastWord]) {
            this.mentionConfig.mentions[1].items = this.functions[lastWord]
                .map((item: string) => ({ id: `.${item}`, name: startCase(item.startsWith('get') ? item.substring(3) : item) }));

            this.mentionConfig = Object.assign({}, this.mentionConfig);
        }

    }

    handleFocus() {
        const textarea = this.textAreaElement.nativeElement;
        const height = textarea.scrollHeight;

        this.Render2.setStyle(textarea, 'min-height', `${height}px`);
        this.Render2.setStyle(textarea, 'height', `${height}px`);
    }

}
