import { Component, Input } from '@angular/core';

@Component({
    selector: 'pro-label',
    template: `
        <h3 class="Label">
            <ng-content></ng-content>
            <ng-container *ngIf="value"> {{ value | translate }} </ng-container>
        </h3>
    `
})

export class LabelComponent {
    @Input() value: string;

}
