import {
    Component,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { TagType } from '@proman/resources/tag';
import { Entity, EntityInterface, EntityNameType } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { getIndexById, mapId } from '@proman/utils';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { Tag } from '@proman/interfaces/entity-interfaces';
import { InlineListService } from '@proman/inline-list/inline-list.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from "@proman/store/curr-user";

@Component({
    selector: 'pm-tags',
    template: `
        <div class="Tags"
             fxLayout="row"
             fxLayoutAlign="start center"
             (click)="$event.stopPropagation()">
            <fa *ngIf="config?.entity"
                   name="tags"
                   class="RightPadding Clickable"
                   (click)="addTag($event)"></fa>
            <div class="Tags-List"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center">
                <ng-container *ngFor="let tag of item?.tags">
                    <div *ngIf="!isCustomer || (isCustomer && tag.public)"
                         class="Tags-List--Item"
                         [ngStyle]="{ 'background-color': '#' + tag.color }"
                         (click)="removeTag(tag)"
                         [title]="tag.description || ''"
                         [proContrastingColor]="tag.color">
                        {{ tag.icon }} {{ tag.name }} </div>
                </ng-container>
            </div>
        </div>
    `,
    styles: [`
        .Tags {
            padding-left: 5px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TagsComponent implements OnInit, OnChanges {
    @Input() item: any = { tags: [] };
    @Input() config: { entity: EntityNameType, tagType?: TagType };
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    entity: EntityInterface;
    isCustomer: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private Entity: Entity,
        private queryExpression: QueryExpressionService,
        private InlineList: InlineListService,
        private ParametersOptions: ParametersOptionsService,
        private store: Store<unknown>,
    ) {
        this.store.select(getCurrUser).subscribe((value) => {
            if (value) {
                this.isCustomer = value.isCustomer;
            }
        })
    }

    ngOnInit() {
        if (this.config && this.config.entity) this.entity = this.Entity.get(this.config.entity);

        this.cd.markForCheck();
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    addTag(event: MouseEvent) {
        let request: any = { sort: { priority: 'desc' } };
        if (this.isCustomer) {
            Object.assign(request, { public: true });
        }
        if (this.config.tagType) {
            Object.assign(request, { type: this.queryExpression.orNull(this.config.tagType) });
        }
        let usedTagsIds: any = [];

        if (this.item && this.item.tags) this.item.tags.forEach((tag: any) => usedTagsIds.push(tag.id));

        if (usedTagsIds.length) request.id = this.queryExpression.notIn(usedTagsIds);

        this.ParametersOptions
            .search({ entity: 'tag', entityParams: request })
            .then((response: any) => {

              response.forEach((tag: Tag) => {
                if (tag.icon) tag.name = `${tag.icon} ${tag.name}`;
              });

                this.InlineList.show({
                    event,
                    data: response,
                    onSelect: (tag: Tag) => {
                        tag.name = tag.name.replace(tag.icon, '');
                        this.item.tags.push(tag);
                        this.cd.markForCheck();

                        this.onChange.emit(this.item.tags.map(mapId));

                        if (this.item.id) {
                            this.entity
                                .addAssociation({
                                    id: this.item.id,
                                    tags: tag.id
                                }).then(() => console.log(this.item));
                        }
                    }
                });

            });

    }

    removeTag(tag: any) {
        let index: number = getIndexById(this.item.tags, tag.id);

        const splice = () => { this.item.tags.splice(index, 1); };

        if (this.item.id) {
            this.entity
                .removeAssociation({
                    id: this.item.id,
                    tags: tag.id
                })
                .then(() => {
                    splice();
                    this.cd.markForCheck();
                });
        } else {
            splice();
        }
    }

}
