import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'fa',
    template: `
        <i class="{{ styles }}"></i>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})

export class FaComponent implements OnInit, OnChanges {
    @Input() name: string;
    @Input() custom: string;
    @Input() faClass: string = 'far';
    @Input() animation?: string;
    @Input() size: string = '2x';
    @Input() tooltip: any = null;

    styles: string;

    constructor(
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        this.styles = `${this.faClass} `;

        this.styles += `fa-${this.name} `;

        this.styles += `fa-${this.size} `;

        if (this.animation) this.styles += `fa-${this.animation} `;

        if (this.custom) this.styles += `fa-${this.custom} `;

        this.cd.markForCheck();

    }

    ngOnChanges() {
        this.ngOnInit();
    }

}
