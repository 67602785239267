import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'pm-advanced-color',
    template: `
        <div class="Color Padding"
             [ngClass]="{ 'Editable': !(noPadding || config?.noPadding) }"
             fxLayout="row"
             fxLayoutAlign="start center">
            <div class="Color-input"
                 [style.background]="getStyleValue()"
                 [ngStyle]="{ 'margin-right': noPadding ? '0' : '10px' }"
                 (click)="openDialog($event)"></div>
            <div class="Editable-Label" *ngIf="!noLabel">
                {{ (config?.label || 'color') | translate }}
            </div>
        </div>

        <div class="ColorPickerOverlay" fxLayoutAlign="space-around center" *ngIf="isVisible" #container>
            <div class="ColorPickerContainer" (click)="$event.stopPropagation();">
                <color-sketch [disableAlpha]="true" [color]="value" (onChangeComplete)="handleClick($event)"></color-sketch>
                <div>
                    <pro-btn [label]="'confirm'" (onClick)="confirm()"></pro-btn>
                    <pro-btn [label]="'close'" (onClick)="isVisible = false"></pro-btn>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .Color {
            padding-left: 4px;
        }
        .Color-input {
            width: 20px;
            height:20px;
            border-radius: 5px;
            border: 1px solid #464646;
            cursor: pointer;
        }

        .ColorPickerContainer {
            background: rgb(255,255,255);
            padding: 5px;
            border-radius: 5px;
        }

        .ColorPickerOverlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*1000 is dialog overlay index*/
            z-index: 1001;
            background: rgba(0, 0, 0, 0.25);
        }

        .ColorPickerContainer {
            position: absolute;
            z-index: 9999;
        }

    `]
})

export class AdvancedColorComponent implements OnInit {
    @Input() value: any;
    @Input() noPadding: boolean;
    @Input() noLabel: boolean;
    @Input() disabled: boolean;
    @Input() config: any = {};
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @ViewChild('container') container: ElementRef;
    isVisible: boolean = false;
    left: string;
    top: string;

    constructor() {

    }

    ngOnInit() {
        if (this.value?.charAt(0) !== '#') {
            this.value = this.value ? '#' + this.value : '#ffffff';
        }
    }

    getStyleValue() {
        return this.value ? (this.value[0] === '#') ? this.value : ('#' + this.value) : '#ffffff';
    }

    openDialog($event: MouseEvent) {
        if (!this.onChange.observers.length || this.disabled) return;

        this.isVisible = true;

        let x: number = $event.clientX;
        let y: number = $event.clientY;

        const windowWidth: number = window.innerWidth;
        const windowHeight: number = window.innerHeight;

        if ((x + 250) > windowWidth) {
            x = windowWidth - 250;
        }

        if ((y + 250) > windowHeight) {
            y = windowHeight - 250;
        }

        this.left = `${x}px`;
        this.top = `${y}px`;

        setTimeout(() => {
            const body = document.querySelector('body');
            const overlay = document.querySelector('.ColorPickerOverlay');

            if (body && overlay) body.appendChild(overlay);

        });
    }

    handleClick(value: any) {
        console.log(value);
        this.value = value.color.hex;
    }

    confirm = () => {
        this.onChange.emit(this.value);
        this.isVisible = false;
    }

}
