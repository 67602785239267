import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pro-clock-timepicker',
  template: `
      <div style="display: flex; align-items: center">
          <span *ngIf="config?.label">{{ config?.label | translate }}</span>
          <ngx-timepicker-field *ngIf="parsedTime" [format]="24" [defaultTime]="parsedTime" (timeChanged)="handleChange($event)"></ngx-timepicker-field>
      </div>
  `
})

export class PromanClockTimepickerComponent implements OnInit{
  @Input() value: any;
  @Input() config: {
    label?: string;
    duration?: boolean;
    isUtcTime?: boolean;
  } = {};
  @Input() disabled: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  parsedTime: any;

  constructor(
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.config?.duration) {
      this.parsedTime = new Date(this.value * 1000).toISOString().substring(11, 16);
    } else {
      this.parsedTime = this.value;
    }
    this.cd.markForCheck();
  }

  handleChange = (value: string) => {
    this.parsedTime = value;
    if (this.config?.duration) {
      let [hours, minutes]: any = this.parsedTime.split(':');
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      minutes += +hours * 60;
      const seconds = +minutes * 60;
      this.onChange.emit(seconds);
    } else {
      this.onChange.emit(this.parsedTime);
    }
  }
}
