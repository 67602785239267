import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PromanCommonComponentsModule } from '../common-components/proman-common-components.module';

import { TableComponent } from './components/table.component';
import { TableFilterDateComponent } from './components/table-filter-date.component';
import { TableDialogComponent } from './components/table-dialog.component';
import {
    TableCellComponent,
    TableCellDefaultComponent,
    TableCellDirectiveComponent,
    TableCellParametersComponent
} from './components/table-cell.component';
import { TableFilterAutocompleteComponent } from './components/table-filter-autocomplete.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { FixedHeaderDirective } from './directives/fixed-header.directive';
import { EntityTableColorComponent } from './components/entity-table-color.component';
import { EntityTableCheckboxComponent } from './components/entity-table-checkbox.component';
import { EntityTableStatusSwitcherComponent } from './components/entity-table-status-switcher.component';
import { EntityTableImageWithUploadComponent } from './components/entity-table-image-with-upload.component';
import { TableSettingsDialogComponent } from './components/table-settings-dialog.component';
import { DragulaModule } from 'ng2-dragula';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { Fa6Module } from '../fa/fa6.module';
import { PromanCheckboxComponent } from '@proman/checkbox';
import { PromanButtonComponent } from '@proman/button';
import { PromanTextSimpleComponent } from '@proman/text-simple';
import { PromanSelectComponent } from '../select';
import { TableDirectiveComponentsMappings, TableModuleConfiguration } from './types';
import { TABLE_DIRECTIVE_COMPONENTS, TABLE_MODULE_CONFIGURATION } from './constants';
import { EntityTableStatusSwitchCheckboxComponent } from './components/entity-table-status-switch-checkbox.component';
import { PromanDateTimeModule } from '../datepicker/proman-date-time.module';
import { PromanSharedDialogsModule } from '../shared-dialogs/proman-shared-dialogs.module';
import { GlobalOverlayModule } from '../overlay/global-overlay.module';
import { TooltipDirectiveModule } from '../tooltip/tooltip-directive.module';
import { EntityTableTextInputComponent } from "@proman/table/components/entity-table-text-input.component";
import { TableCacheService } from '@proman/table/services/table-cache.service';
import { TableActionsParserService } from '@proman/table/services/table-actions-parser.service';
import { TableHelperService } from '@proman/table/services/table-helper.service';
import { TableParametersFormatterService } from '@proman/table/services/table-parameters-formatter.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DragulaModule.forRoot(),
        MatLegacyProgressBarModule,
        MatLegacyMenuModule,
        FlexLayoutModule,
        Fa6Module,
        PromanCheckboxComponent,
        PromanCommonComponentsModule,
        PromanTextSimpleComponent,
        PromanSelectComponent,
        PromanDateTimeModule,
        PipesModule,
        PromanSharedDialogsModule,
        SharedDirectivesModule,
        GlobalOverlayModule,
        TooltipDirectiveModule,
        PromanButtonComponent,
    ],
    providers: [
        // lazy loaded modules (pos) cannot get configuration values passed without providers provided
        TableCacheService,
        TableActionsParserService,
        TableHelperService,
        TableParametersFormatterService,
        ParametersOptionsService,
    ],
    declarations: [
        TableComponent,
        TableFilterDateComponent,
        TableDialogComponent,
        TableCellComponent,
        TableCellDefaultComponent,
        TableCellParametersComponent,
        TableCellDirectiveComponent,
        TableFilterAutocompleteComponent,
        EntityTableColorComponent,
        EntityTableCheckboxComponent,
        EntityTableStatusSwitcherComponent,
        EntityTableStatusSwitchCheckboxComponent,
        EntityTableImageWithUploadComponent,
        EntityTableTextInputComponent,
        TableSettingsDialogComponent,
        FixedHeaderDirective,
    ],
    exports: [
        TableComponent,
        TableFilterDateComponent,
        TableDialogComponent,
        TableCellComponent,
        TableCellDefaultComponent,
        TableCellParametersComponent,
        TableCellDirectiveComponent,
        TableFilterAutocompleteComponent,
        EntityTableColorComponent,
        EntityTableCheckboxComponent,
        EntityTableStatusSwitcherComponent,
        EntityTableStatusSwitchCheckboxComponent,
        EntityTableImageWithUploadComponent,
        EntityTableTextInputComponent,
        TableSettingsDialogComponent,
        FixedHeaderDirective,
    ]
})

export class PromanTableModule {
  static forRoot(config: {
    directives: TableDirectiveComponentsMappings,
    configuration: TableModuleConfiguration
  }): ModuleWithProviders<PromanTableModule> {
    // User config get logged here
    return {
      ngModule: PromanTableModule,
      providers: [
        {
          provide: TABLE_DIRECTIVE_COMPONENTS,
          useValue: config.directives
        },
        {
          provide: TABLE_MODULE_CONFIGURATION,
          useValue: config.configuration
        },
      ]
    };
  }
}
