import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';
import { Entity } from '@proman/services/entity.service';
import * as lodash from 'lodash';
import { deepCopy, isObject } from '@proman/utils';

@Component({
    selector: 'pm-config-config-field',
    template: `
        <ng-container *ngIf="item" [ngSwitch]="item.form?.type">
            <pm-txt *ngSwitchCase="'string'"
                    [value]="item.value"
                    [config]="{ type: 'text' }"
                    (onChange)="handleChange($event)"></pm-txt>

            <pro-checkbox *ngSwitchCase="'boolean'"
                       [value]="item.value"
                       [config]="{}"
                       (onChange)="handleChange($event)"></pro-checkbox>

            <pm-number *ngSwitchCase="'number'"
                       [value]="item.value"
                       [config]="{}"
                       (onChange)="handleChange($event)"></pm-number>

            <pm-number *ngSwitchCase="'integer'"
                       [value]="item.value"
                       [config]="{}"
                       (onChange)="handleChange($event)"></pm-number>

            <pm-number *ngSwitchCase="'float'"
                       [value]="item.value"
                       [config]="{}"
                       (onChange)="handleChange($event)"></pm-number>

            <pro-autoc *ngSwitchCase="'select'"
                      [value]="item.value"
                      [config]="{ label: '' }"
                      [getOptions]="parseSelectOptions"
                      (onChange)="handleChange($event)"></pro-autoc>

            <pro-select *ngSwitchCase="'multi_select'"
                        [value]="item.value"
                        [config]="{ label: '', multiple: true }"
                        [options]="item.form.data"
                        (onChange)="handleChange($event)"></pro-select>

            <pro-autoc *ngSwitchCase="'select_entity'"
                      [value]="item.value"
                      [config]="{ label: '', entity: item.form.data['entity'], entityParams: item.form.data['params'] }"
                      (onChange)="handleChangeAutocomplete($event)"></pro-autoc>

            <pm-advanced-color *ngSwitchCase="'color'"
                               [value]="item.value"
                               [noLabel]="true"
                               [config]="{}"
                               (onChange)="handleChange($event)"></pm-advanced-color>

            <pro-editable-container *ngIf="!item.form">
                {{ item.value }}
            </pro-editable-container>

        </ng-container>
    `
})

export class ConfigFormFieldComponent implements OnInit {
    @Input() item: { group: string; name: string; value: any; form: any};
    @Input() config: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    currUser: CurrUser;
    tempValue: string;

    constructor(
        private store: Store<unknown>,
        private Entity: Entity,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {
        if (!this.item && this.config?.default) {
            this.handleChange(this.config?.default);
        }
        if (this.item && this.item.form && this.item.form.type === 'multi_select') {
            if (!isObject(this.item.form.data[0])) this.item.form.data = this.item.form.data.map((i: string) => ({ id: i, name: i }));
        }
    }

    parseSelectOptions = (query: string) => {
        if (this.item.form?.data) {
            const newArr: any[] = [];
            for (let i = 0; i < this.item.form.data.length; i++) {
                newArr.push({name: this.item.form.data[i]})
            }
            return Promise.resolve().then(() => {
                return newArr;
            })
        } else {
            return null;
        }
    }

    handleChange(value: any) {
        if (this.item.form.type === 'multi_select') {
            let tempValue = deepCopy(this.item.value);
            value = (value as any[]).map((i: any) => i.id);
            (tempValue as any[]).push(...value);
            tempValue = lodash.uniq(tempValue);
            this.onChange.emit(tempValue);
            this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: tempValue });

        } else {
            if (value.name) {
                this.tempValue = value.name;
                this.item.value = value.name;
            } else {
                this.item.value = value;
            }
            this.onChange.emit(value);
            this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: this.item.value});
        }
    }

    handleChangeAutocomplete(value: any) {
        this.item.value = value.id;
        this.onChange.emit(value);
        this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: this.item.value});
    }

}
