import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pro-expression-format',
    template: `
        <div class="Expression"
             fxLayout="column">
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center">
                <div>
                    {{ 'expression_builder' | translate }}</div>
                <pro-btn *ngFor="let format of formats"
                        (onClick)="loadFormat(format)"
                        [label]="format + '_expression' | translate"
                        theme="accent"></pro-btn>
            </div>

            <div class="Expression-Container" [ngSwitch]="format">
                <pro-expression-simple *ngSwitchCase="'simple'"
                                       (onChange)="updateModel($event)"></pro-expression-simple>
            </div>

            <div class="Expression-Container" [ngSwitch]="format">
                <pro-expression-case *ngSwitchCase="'case'"
                                     (onChange)="updateModel($event)"></pro-expression-case>
            </div>

            <div class="Expression-Container" [ngSwitch]="format">
                <pro-expression-condition *ngSwitchCase="'condition'"
                                          (onChange)="updateModel($event)"></pro-expression-condition>
            </div>

        </div>

    `
})

export class ExpressionFormatComponent {
    @Input() value: string;
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    formats: string[] = [ 'simple', 'condition' ];
    format: string;

    constructor() {}

    loadFormat(format: string) {
        this.format = format;
    }

    updateModel(expression: string) {
        this.value = expression;

        this.format = null;

        this.onChange.emit(expression);

    }

}
