import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { Entity } from '@proman/services/entity.service';
import { OPERATORS_TYPES } from '@proman/utils/expressions_config';

@Component({
    selector: 'pro-expression-simple',
    template: `
        <div>
            <h4>
                <span>
                    {{ 'expression' | translate }}
                </span>
                <span *ngFor="let condition of conditions">
                    <span *ngIf="condition.config?.type === 'parameter'">{{ condition.value?.name }}</span>
                    <span *ngIf="condition.config?.type !== 'parameter'">{{ condition.value }}</span>
                </span>
            </h4>
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 *ngFor="let condition of conditions;let $index = index; let $last = last;">

                <pro-btn *ngIf="condition.value && !condition.isEditMode"
                        (onClick)="edit(condition)"
                        icon="edit"
                        theme="primary"></pro-btn>
                <pro-btn *ngIf="condition.value && condition.isEditMode"
                        (onClick)="confirmEdit(condition)"
                        icon="check-circle"
                        theme="primary"></pro-btn>
                <pro-btn *ngIf="condition.value && condition.isEditMode"
                        (onClick)="deleteConditionItem(conditions, $index)"
                        icon="trash"
                        theme="warn"></pro-btn>
                <pro-select *ngIf="!condition.value || condition.isEditMode"
                        [ngClass]="{ 'width-75' : condition.config?.type === 'numeric' }"
                        [value]="condition.config"
                        [config]="{ label: 'type', isNone: true }"
                        [options]="types"
                        (onChange)="resetValue(condition, $event)"></pro-select>
                <pro-text-simple *ngIf="condition.config?.type === 'numeric'"
                                 [value]="condition.value"
                                 [config]="{}"
                                 (onChange)="set(condition, $event)"
                                 class="width-50"></pro-text-simple>
                <pro-select *ngIf="condition.config?.type === 'parameter'"
                        [value]="condition.value"
                        [config]="{ label: 'value', groupBy: 'context' }"
                        (onChange)="set(condition, $event)"
                        [options]="parameterOptions"></pro-select>
                <pro-select *ngIf="condition.config?.type === 'operator'"
                        [value]="condition.value"
                        [config]="{ label: 'operator' }"
                        [options]="operatorsTypes"
                        (onChange)="set(condition, $event.id)"></pro-select>
                <pro-btn *ngIf="$last && condition.value"
                        (onClick)="addNewCondition()"
                        icon="plus"
                        theme="accent"></pro-btn>
            </div>
            <small>
                {{ previewValue }}
            </small>
            <div>
                <pro-btn *ngIf="conditions[0].value"
                        (onClick)="addExpression()"
                        [label]="'add_expression' | translate"></pro-btn>
            </div>
        </div>

    `,
    styles: [`
        .width-75 { width: 75% }
        pro-select { min-width: 90px; }
    `]
})

export class ExpressionSimpleComponent implements OnInit {
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    operatorsTypes: any;
    conditions: any = [{}];
    types: any;
    previewValue: any;
    parameterOptions: any;

    constructor(
        private Entity: Entity,
        private filter: FilterService,
    ) {
        this.types = [
            {
                name: this.filter.translate('numeric'),
                type: 'numeric'
            },
            {
                name: this.filter.translate('parameter'),
                type: 'parameter'
            },
            {
                name: this.filter.translate('operator'),
                type: 'operator'
            }
        ];

        this.operatorsTypes = OPERATORS_TYPES.map((item: string) => {
            return {
                id: item,
                name: item
            }
        });

    }

    ngOnInit() {
        this.Entity.get('parameter')
            .search({})
            .then((response: any) => this.parameterOptions = response);
    }

    formatExpression() {
        const expressionValues = this.conditions;
        let output = '';

        if (expressionValues && expressionValues.length) {
            for (const expressionConditionValue of expressionValues) {
                const config = expressionConditionValue.config;
                const value = expressionConditionValue.value;

                if (expressionConditionValue && config) {
                    if (value && (config.type === 'numeric' || config.type === 'operator')) {
                        output += ' ' + expressionConditionValue.value;

                    } else if (config.type === 'parameter' && value) {
                        output += ' p(' + value.id + ')';

                    }

                }

            }
        }

        return output;
    }

    set = (item: any, value: any) => {
        item.value = value;
    };

    addNewCondition() {
        this.conditions.push({ config: {} });
        this.update()
    }

    edit(item: any) {
        item.isEditMode = true;
        this.update();
    }

    deleteConditionItem(items: any, index: number) {
        items.splice(index, 1);
        this.update();
    }

    confirmEdit(item: any) {
        delete item.isEditMode;
        this.update();
    }

    resetValue(item: any, value: any) {
        delete item.value;
        item.config = value;

        this.update();
    }

    addExpression() {
        const output = this.formatExpression();

        this.onChange.emit(output);
    }

    update() {
        this.previewValue = this.formatExpression();
    }

}
