import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { ExpressionHumanizeService } from '../expression-humanize.service';

@Component({
    selector: 'pro-expression-humanize',
    template: `
        <div [ngSwitch]="isDisplayFull" [ngClass]="{ 'Clickable': !isDisplayFull }">
            <div [innerHTML]="displayVal | safeHTML" (click)="handleClick($event)" class="Clickable" [proOverlay]="{ type: 'text', data: textVal }"></div>    
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpressionHumanizeComponent implements OnChanges {
    @Input() value: any;
    maxLength: number = 300;
    displayVal: string = '';
    fullVal: string = '';
    textVal: string = '';
    isDisplayFull: boolean;

    constructor(
        private ExpressionHumanizeService: ExpressionHumanizeService,
        private Filter: FilterService,
        private cd: ChangeDetectorRef,

    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.ExpressionHumanizeService
            .humanize(changes.value.currentValue)
            .then((response: string) => {
                this.displayVal = this.Filter.maxLength(response, this.maxLength);
                this.fullVal = response;
                this.textVal = response.replace(/(<([^>]+)>)/ig, '');

                this.isDisplayFull = this.fullVal === this.displayVal;

                this.cd.markForCheck();
            });
    }

    handleClick($event: MouseEvent) {
        $event.stopImmediatePropagation();

        if (!this.isDisplayFull) {
            this.isDisplayFull = true;
            this.displayVal = this.fullVal;

        } else {
            this.isDisplayFull = false;
            this.displayVal = this.Filter.maxLength(this.fullVal, this.maxLength);

        }

        this.cd.markForCheck();

    }

}
