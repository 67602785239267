import { Inject, Injectable } from '@angular/core';
import { ACL, PermissionType } from '../../services/acl.service';
import { TableButton } from '../../interfaces/object-interfaces';
import { isDefined } from '../../utils';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { TABLE_STATE_PARSER } from '../constants';
import { TableStateParser } from '../types';

@Injectable({ providedIn: 'root' })
export class TableActionsParserService {

    constructor(
        @Inject(TABLE_STATE_PARSER) private stateParser: TableStateParser,
        private MatLegacyDialog: MatLegacyDialog,
        private ACL: ACL,

    ) {

    }

    getState(button: TableButton) {
        let state = button.state;

        return state;
    }

    getStateChangeCallback = (button: TableButton) => {

        return (...args: any[]) => {
            let state = this.getState(button);
            let row;
            let isNewTab = false;
            const event = args[1];
            row = args[0];

            if (event && event instanceof MouseEvent) {
                isNewTab = (event.ctrlKey || event.metaKey);
            }

            if (row instanceof MouseEvent) {
                row = null;
            }

          this.stateParser.goToState({
            name: state.name,
            id: row && row.id || row || null,
            queryParams: (isDefined(state.queryParams) ? state.queryParams(row) : null) || {} ,
            newTab: isNewTab
          })
        };
    };

    addStateCallback(button: any) {
        button.callback = this.getStateChangeCallback(button);
    }

    actionEnabled(aclRoot: any, button: any) {
        // button semantic action might be defined in button template or icon key
        let action = button.action || button.template || button.icon || button.icon;
        let enabled = (typeof button.acl === 'undefined') && this.ACL.check(`${aclRoot}.${action}` as PermissionType) ||
            button.acl === null ||
            this.ACL.check(button.acl);

        return enabled;
    };

    static addTheme(button: TableButton) {

        if (!button.theme) {

            if (button.icon === 'delete' || button.icon === 'remove_circle' || button.icon === 'trash' || button.icon === 'times') {
                button.theme = 'warn';

            } else if (button.icon === '?' || button.icon === 'question-circle') {
                button.theme = 'primary';

            } else {
                button.theme = 'accent';

            }

        }

        return button;
    }

    static adjustShow(button: TableButton) {
        if (!button.show) button.show = () => true;

        return button;
    }

    static addTooltip(button: TableButton) {
        button.tooltip = button.tooltip || null;

        return button;
    }

    parseButtons(aclRoot: string, buttons: TableButton[]) {

        if (buttons) {
            return buttons
                .filter((button) => this.actionEnabled(aclRoot, button))
                .map(this.addCallback)
                .map(TableActionsParserService.addTheme)
                .map(TableActionsParserService.addTooltip)
                .map(TableActionsParserService.adjustShow);

        } else {
            return [];

        }

    }

    addDialogCallback2(button: TableButton) {
        button.callback = (row: any) => {
            let dialogConfig = Object.assign({}, button.dialog2);
            let dialogRef;

            if (typeof dialogConfig.locals === 'undefined') dialogConfig.locals = {};

            if (!(row instanceof MouseEvent)) dialogConfig.locals.item = row;

            dialogRef = this.MatLegacyDialog.open(button.dialog2!.component, {
              data: dialogConfig.locals,
              ...(dialogConfig.options || {})
            });
            return dialogRef
                .afterClosed()
                .toPromise()
                .then((result: any) => {

                    if (button.state) {
                        this.getStateChangeCallback(button)(result);

                    } else {
                        if (result) {
                            if (button.onSuccess) button.onSuccess(result);

                        } else {
                            if (button.onCancel) button.onCancel();

                        }

                    }

                });
        };
    };

    addCallback = (button: any) => {

        if (button.dialog2) {
            this.addDialogCallback2(button);

        } else if (button.state) {
            this.addStateCallback(button);

        }

        return button;
    };

    addDefaultRowCallback(config: any, buttons: any) {

        for (let button of buttons) {

            if (button.callback && button.defaultRowAction) {
                config.onRowClick = button.callback;

                break;

            }

        }
    };
}
