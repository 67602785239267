import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TooltipPosition } from '@proman/tooltip/tooltip.directive';

@Component({
    selector: 'pm-switch',
    template: `
        <mat-slide-toggle [checked]="value"
                          (change)="handleChange($event)"
                          [disabled]="disabled"
                          [proTooltip]="tooltip"
                          [attr.data-switch]="config?.label || config?.icon"
                          [attr.data-value]="value">
            {{ config.label | translate }}
            <fa *ngIf="config.icon" [name]="config.icon"></fa>
        </mat-slide-toggle>
    `,
    styles: [`
        mat-slide-toggle {
            position: relative;
        }
        fa {
            margin-left: 8px;
        }
        :host {
            padding: 4px 0;
        }
    `]
})
export class SwitchComponent implements OnInit{
    @Input() config: {
        label?: string;
        disabled?: boolean;
        icon?: string;
    } = {};
    @Input() value: any;
    @Input() disabled: any;
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() tooltip: string;
    @Input() tooltipPosition: TooltipPosition;
    @Input() disabledTooltip: string;

    ngOnInit() {
        if (this.value === 'no') this.value = false;
        if (this.value === 'yes') this.value = true;
    }

    handleChange($event: any) {
        this.onChange.emit($event.checked);
    }
}
