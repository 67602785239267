import {
    Directive,
    ElementRef,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { delay, findScrollElement } from '../../utils';
import { Subscription } from '@proman/rxjs-common';
import { ObservablesService } from '../../services/observables.service';
import { isFunction } from "lodash";

@Directive({
    selector: '[proFixedHeader]',
})
export class FixedHeaderDirective implements OnInit, OnDestroy, OnChanges {
    @Input('proFixedHeader') showHeader: boolean;
    @Input('timeStamp') timeStamp: number;

    scrollElement: HTMLElement;
    isVisible: boolean;
    isConfigSet: any;
    container: HTMLDivElement;
    header: HTMLTableElement;
    horizontalScrollContainer: HTMLDivElement;
    thead: any;
    theadClone: any;
    offsetTop: any;
    dialogTimeStampSub: Subscription;

    constructor(
        private element: ElementRef,
        private Observables: ObservablesService,
    ) {
        this.dialogTimeStampSub = this.Observables.dialogTimeStamp.subscribe(() => this.hide());
        window.addEventListener('resize', this.adjustPositionCallback);
    }

    async ngOnInit() {
        await delay(2500);

        if (this.showHeader) {
            this.scrollElement = await findScrollElement(this.element.nativeElement);
            if (this.canAddListener(this.scrollElement)) this.scrollElement.addEventListener('scroll', this.handleScroll);
            this.horizontalScrollContainer = document.querySelector('.TableContainer');
            this.horizontalScrollContainer?.addEventListener('scroll', this.handleHorizontalScroll);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        let timeStamp: any = changes.timeStamp;
        let enabled: any = changes.showHeader;

        if (timeStamp && this.header) {
            this.hide();
            this.header.remove();
            this.header = null;

            this.createHeader();

        }

        if (enabled) {
            this.ngOnInit();
        }

    }

    ngOnDestroy() {
        if (this.showHeader) {
            if (this.container) this.container.remove();

            if (this.scrollElement && isFunction(this.scrollElement.removeEventListener)) {
                this.scrollElement?.removeEventListener('scroll', this.handleScroll);
                document.querySelector('.TableContainer')?.addEventListener('scroll', this.handleHorizontalScroll);
            }

        }

        this.dialogTimeStampSub.unsubscribe();

      window.removeEventListener('resize', this.adjustPositionCallback);

    }

   adjustPositionCallback = () => this.adjustPosition();

    adjustPosition = async (delayTime: number = 500) => {
        await delay(delayTime);
        const left = this.element.nativeElement.getBoundingClientRect().left;

        if (this.hasStyle(this.container)) {
          this.container.style.left = `${left}px`;
          this.container.style.width = `${window.innerWidth - left}px`;
        }

        if (this.offsetTop) {
          this.container.style.top = this.offsetTop + 'px';

        }
    };

    fixHeader() {

        if (this.offsetTop) {
            return this.scrollElement.scrollTop > this.element.nativeElement.offsetTop;

        } else {
            return this.element.nativeElement.getBoundingClientRect().top < 0;

        }

    };

    handleScroll = () => {
        let isHeaderFixed;

        if (!this.isConfigSet) {
            this.setConfig();

        }

        if (!this.header) {
            this.createHeader();

        }

        isHeaderFixed = this.fixHeader();

        if (isHeaderFixed && !this.isVisible) {
            this.show();

        } else if (!isHeaderFixed && this.isVisible) {
            this.hide();

        }

    };

    setConfig() {
        this.offsetTop = this.scrollElement.getBoundingClientRect().top;
        this.isConfigSet = true;
    }

    setDimensions() {
        let width =  this.element.nativeElement.offsetWidth + 'px';

        if (this.hasStyle(this.header)) {
          this.header.style.width = width;
          this.header.style.minWidth = width;
        }

        // 'th' for entityTable
        let headersTh = this.thead.getElementsByTagName('th');
        let columnsTh = this.theadClone.getElementsByTagName('th');

        for (let iter = 0; iter < headersTh.length; iter++) {
            let width = headersTh[iter].offsetWidth;

            columnsTh[iter].style.width = width + 'px';
            columnsTh[iter].style.boxSizing = 'border-box';

        }

        // 'td' for boards
        let headersTd = this.thead.getElementsByTagName('td');
        let columnsTd = this.theadClone.getElementsByTagName('td');

        for (let iter = 0; iter < headersTd.length; iter++) {
            let width = headersTd[iter].offsetWidth;

            columnsTd[iter].style.width = width + 'px';
            columnsTd[iter].style.boxSizing = 'border-box';

        }

        this.adjustPosition(0);


    }

    createHeader() {
        this.thead = this.element.nativeElement.getElementsByTagName('thead')[0];
        this.theadClone = this.thead.cloneNode(true);
        this.container = document.createElement('div');
        this.header = document.createElement('table');

        this.container.classList.add('FixedHeader');
        this.header.classList.add('Table');

        let table = document.querySelector('.EntityTable');

        if (table) {
            if (table.classList.contains('isLineNumbering')) this.header.classList.add('isLineNumbering');
            if (table.classList.contains('with-multiselect')) this.header.classList.add('with-multiselect');

        }

        [].forEach.call(this.theadClone.querySelectorAll('pm-txt'), async (txtEl: HTMLElement) => {
            const label = txtEl.querySelector('input').getAttribute('ng-reflect-placeholder');
            const divWithLabel = document.createElement('div');
            divWithLabel.innerText = label;
            txtEl.parentElement.appendChild(divWithLabel);
            txtEl.parentElement.removeChild(txtEl);
        });


        this.header.appendChild(this.theadClone);
        this.container.appendChild(this.header);

        this.setDimensions();

        document.querySelector('body').appendChild(this.container);
        this.hide();
    }

    show() {
        this.container.classList.remove('DisplayNone');
        this.isVisible = true;
    }

    hide = () => {
        if (!this.container) return;

        this.container.classList.add('DisplayNone');

        this.isVisible = false;
    }

    handleHorizontalScroll = () => {
      if (this.hasStyle(this.header)) this.header.style.left = `${-1 * this.horizontalScrollContainer.scrollLeft}px`;
    };

    hasStyle = (item: { style?: unknown }) => !!item && !!item.style;

    canAddListener = (item: { addEventListener?: unknown }) => !!(item && typeof item.addEventListener === 'function')

}
