import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromanClockTimepickerComponent } from '@proman/clock-timepicker/proman-clock-timepicker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PipesModule } from '@proman/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    NgxMaterialTimepickerModule,
    PipesModule,
  ],
  declarations: [PromanClockTimepickerComponent],
  exports: [PromanClockTimepickerComponent],
})

export class PromanClockTimepickerModule {

}
