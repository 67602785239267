import { AbstractControl } from '@angular/forms';

export function NumberValidator(control: AbstractControl) {
    let value = control.value;
    let isValid = (!isNaN(parseFloat(value)) && isFinite(value) || !value);

    if (!isValid) {
        return { isValidNumber: true };

    }

    return null;
}
