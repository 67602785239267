import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, OnInit } from '@angular/core';
import { ImagePathService } from '../../services/image-path.service';
import { FilePreviewService } from '../../services/file-preview.service';
import { PromanFile } from '../../interfaces/entity-interfaces';
import { PromanFileIconType } from '../../resources/file';

const DEFAULT_THUMBNAIL_SIZE = 50;
const DEFAULT_IMAGE = 'assets/images/placeholder.png';

@Component({
    selector: 'pro-thumbnail',
    template: `
        <div class="Thumbnail" [ngStyle]="{ 'width':  size + 'px' , 'height': size + 'px' }" [ngClass]="{ 'PointerEventsNone': hideOverlay }">
            <img [ngStyle]="{ 'width':  size + 'px' , 'height': size + 'px', borderRadius: borderRadius + 'px' }"
                 class="image-{{ file?.id }}"
                 *ngIf="image"
                 [src]="image"
                 [proOverlay]="{ type: 'image', data: getOverlayLink() }"
                 (click)="preventView ? null : preview($event)"
                 [ngClass]="{ 'Thumbnail-avatar': isAvatar, MarginAuto: !isDefaultSize, Clickable: !preventView }"
                 [proImageError]="'assets/images/placeholder.png'"
            />
            <fa *ngIf="!image"
                      class="Thumbnail-preview"
                      [ngClass]="{ 'Clickable': !preventView }"
                      (click)="preventView ? null : preview($event)"
                      [name]="faIcon"
                      size="4x"
            ></fa>
        </div>
    `,
    styles: [`
        .Thumbnail {
            overflow: hidden;
        }
        .Thumbnail-avatar {
            margin: 8px 16px 8px 0;
            box-sizing: content-box;
        }
        .Thumbnail-preview {
            width: 50px;
            height: 50px;
            position: relative;
            top: 2px;
            left: 5px;
        }
        img {
            display: block;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush

})

export class PromanThumbnailComponent implements OnInit, OnChanges {
    @Input() isAvatar: boolean = false;
    @Input() size: number = DEFAULT_THUMBNAIL_SIZE;
    @Input() file: PromanFile;
    @Input() borderRadius: number;
    @Input() config: { size?: '1x'|'2x' };
    @Input() preventView: boolean = false;
    @Input() hideOverlay: boolean;
    @Input() type: PromanFileIconType = 'icon';
    image: string = DEFAULT_IMAGE;
    action: string = 'blank';
    icon: string = 'assets/images/file.png';
    nameAttr: string;
    faIcon: string = 'file';
    _isDocument: boolean;
    _isSpreadsheet: boolean;
    _isCompressed: boolean;
    isDefaultSize: boolean = true;

    constructor(
        private cd: ChangeDetectorRef,
        private ImagePath: ImagePathService,
        private FilePreview: FilePreviewService,
    ) {

    }

    ngOnChanges() {
        this.ngOnInit();
    }

    isImage = (ext: string) => ['gif', 'v', 'jpeg', 'jpg', 'xlsx', 'docx', 'pdf'].includes(ext);

    isDocument(ext: string) {
        this._isDocument = ['pdf'].indexOf(ext) > -1;

        return this._isDocument;
    }

    isSpreadsheet = (ext: string) => {
        this._isSpreadsheet = ['xls', 'xlsx'].indexOf(ext) > -1;

        return this._isSpreadsheet;
    };

    isVideo = (ext: string) => {
        return ['mov', 'mp4'].includes(ext);
    };

    isCompressed = (ext: string) => {
        return ['zip', 'rar', '7z'].includes(ext);
    };

    ngOnInit() {
        if (this.config?.size) {
            if (this.config.size === '1x') {
                this.size = (DEFAULT_THUMBNAIL_SIZE / 2);
            }
        }

        this.isDefaultSize = this.size === DEFAULT_THUMBNAIL_SIZE;

        const file = this.file;

        if (file) {
            this.nameAttr = file.filename ? 'filename' : 'name';

            let ext = file.extension;
            let newExt = file.newExtension;

            if (this.isVideo(ext) || this.isVideo(newExt)) {
                this.image = null;
                this.faIcon = 'file-video';
            } else if (this.isCompressed(ext) || this.isCompressed(newExt)) {
                    this.image = null;
                    this.faIcon = 'file-archive';
            } else if (file) {
                this.image = this.ImagePath.getFile(file, this.type);
            }
        }

        this.cd.markForCheck();
    }

    preview = (event: MouseEvent) => {
        event.stopPropagation();
        this.FilePreview.show(this.file);
    };

    getOverlayLink() {
        if (!this.file) return null;

        if (this.file.newId) {
            return this.ImagePath.getFile(this.file, 'png');
        }

        if (this.file[this.nameAttr]) {
            return this.ImagePath.getFile(this.file[this.nameAttr], 'icon');
        } else {
            return 'assets/images/pdf.png';
        }
    }

}
