import {
    Component, Input, ViewChild,
    OnInit, OnDestroy, ComponentRef,
    ViewContainerRef, ComponentFactoryResolver,
    ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Inject
} from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { DateTimeFormatService } from '../../services/date-time-format.service';
import { TableParametersFormatterService } from '../services/table-parameters-formatter.service';
import { TableHelperService } from '../services/table-helper.service';
import moment from 'moment';
import { formatNumber, isDefined, get, isNumber, isNull } from '../../utils';
import { getFieldValue } from '../utils/tools';
import { EntityTableImageWithUploadComponent } from './entity-table-image-with-upload.component';
import { EntityTableStatusSwitcherComponent } from './entity-table-status-switcher.component';
import { EntityTableCheckboxComponent } from './entity-table-checkbox.component';
import { EntityTableStatusSwitchCheckboxComponent } from './entity-table-status-switch-checkbox.component';
import { EntityTableColorComponent } from './entity-table-color.component';
import { TableField, TableFieldFormatterType } from '../../interfaces/object-interfaces';
import { ACL, PermissionType } from '../../services/acl.service';
import { Currency } from '../../interfaces/entity-interfaces';
import { CurrenciesService } from '../../services/currencies.service';
import { PromanThumbnailComponent } from '../../common-components/components/proman-thumbnail.component';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { TABLE_DIRECTIVE_COMPONENTS, TABLE_STATE_PARSER } from '../constants';
import { TableDirectiveComponentsMappings, TableStateParser } from '../types';
import {EntityTableTextInputComponent} from "@proman/table/components/entity-table-text-input.component";

const DEFAULT_OUTPUT = '-';
const DEFAULT_MAX_LENGTH = 50;

export interface TableCellDirectiveComponent {
    item: any;
    config: any;
    callback: any;
}

export abstract class TableCellInstance {
    row: any;
    column: any;
    rowIndex: any;
    callback: EventEmitter<any>;
}
// -------------- TABLE CELL DEFAULT -----------------
@Component({
    selector: 'pro-table-cell-default',
    template: `
        <ng-container [ngSwitch]="isCompile">
            <ng-container *ngSwitchCase="true">
                <div *ngIf="isIcon" class="EntityTable-textIcon-container"
                     fxLayout="row"
                     fxLayoutAlign="start center"
                     [proOverlay]="{ type: 'button', data: result.tooltip  || '' }"
                >
                    <span class="{{ result.icon.color }}" >
                        <fa [name]="result.icon.icon"></fa>
                    </span>
                    <div>{{ result.text }}</div>
                </div>
                <div *ngIf="!isIcon" [innerHTML]="result | safeHTML"></div>
            </ng-container>

            <ng-container *ngSwitchCase="false" [ngSwitch]="(isState)">

                <a *ngSwitchCase="true" [routerLink]="stateParams.path" [queryParams]="stateParams.params" proClickStopPropagation>
                    <ng-container [ngSwitch]="translate">
                        <ng-container *ngSwitchCase="true">{{ result | translate}}</ng-container>
                        <ng-container *ngSwitchCase="false">{{ result }}</ng-container>
                    </ng-container>
                </a>

                <ng-container *ngSwitchCase="false">
                    <ng-container [ngSwitch]="translate">
                        <ng-container *ngSwitchCase="true">{{ result | translate}}</ng-container>
                        <ng-container *ngSwitchCase="false">
                            <ng-container [ngSwitch]="!!fullResult">
                                <ng-container *ngSwitchCase="true">
                                  <span [proOverlay]="{ type: 'text', data: fullResult }"
                                        (click)=" $event.preventDefault(); $event.stopPropagation(); result = fullResult; fullResult = null;">{{ result }}</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="false">
                                  <span [ngClass]="[customClass]">{{ result }}</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </ng-container>

        </ng-container>
    `,
    styles: [`
        :host { white-space: pre; }
        .PreWrap {
          white-space: pre;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TableCellDefaultComponent extends TableCellInstance implements OnInit {
    formatter: TableFieldFormatterType;
    formatterConfig: string;
    result: any;
    fullResult: any;
    defaultDecimalPlaces: number;
    translate: boolean = false;
    isCompile: boolean = false;
    isState: boolean = false;
    isIcon: boolean = false;
    cellMarker: (value: any) => string|void;
    treePadding: boolean = false;
    currenciesByName: { [key: string]: Currency };
    stateParams: { path: string[]; params?: any };
    customClass: string = '';

    constructor(
      @Inject(TABLE_STATE_PARSER) private stateParser: TableStateParser,
      private filter: FilterService,
        private cd: ChangeDetectorRef,
        private DateTime: DateTimeFormatService,
        private TableHelper: TableHelperService,
        private Currencies: CurrenciesService,
        private ACL: ACL,
    ) {
        super();
        this.currenciesByName = this.Currencies.dataMappedByName;
    }

    ngOnInit() {
        let column: TableField = this.column;
        let row: any = this.row;
        this.treePadding = column.treePadding;

        const formatter: TableFieldFormatterType = column.formatter;
        const formatterConfig: string = column.formatterConfig;
        const prefix: string = column.prefix;
        const postfix: string = column.postfix;
        const expression: string = column.expression;
        const maxLength: number = column.config?.showFullResult ? 0 : isDefined(column.maxLength) ? (column.maxLength as number) : DEFAULT_MAX_LENGTH;
        this.cellMarker = column.cellMarker;
        let value: any;
        let date: any;

        this.defaultDecimalPlaces = this.TableHelper.getDefaultDecimalPlaces();
        this.result = getFieldValue(column, row);

        if (isDefined(column.translate)) this.translate = !!column.translate;
        if (isDefined(prefix)) this.result = `${prefix}${this.result}`;
        if (isDefined(postfix)) this.result = `${this.result}${postfix}`;
        if (isDefined(expression)) this.result = `${row[column.name as string]}`;

        value = this.result;

        if (column.state && (isDefined(column.state?.acl) ? this.ACL.check(column.state!.acl as PermissionType) : true)) {
            this.stateParams = this.getStateParams(column);
        }

      if (formatter === 'dateTime') {

            if (value) {
                if (typeof value === 'string' && value.startsWith('-')) {
                    this.result = DEFAULT_OUTPUT;

                } else {
                    date = moment(value);

                    if (date.isValid()) {
                        this.result = date.format(this.DateTime.get(formatterConfig || '_date_js')).replace('00:00', '');

                    }
                }

            }
        } else if (formatter === 'money') {
            if (value && value.amount !== null && value.currency) {
                const currencyValue = this.currenciesByName[value.currency] && this.currenciesByName[value.currency].shorthand || '';
                const numericValue = formatNumber(
                    !isNumber(value.amount) ? 0 : value.amount,
                    (column.formatterConfig ?? this.defaultDecimalPlaces)
                );
                this.result = `${currencyValue} ${numericValue}`;

            } else {
                this.result = DEFAULT_OUTPUT;

            }

        } else if (formatter === 'numeric') {

            const parsedValue = parseFloat(value);

            if (!!isNumber(parsedValue)) {
                this.result = formatNumber(parsedValue,  (column.formatterConfig ?? this.defaultDecimalPlaces));

            } else {
                this.result = DEFAULT_OUTPUT;

            }

        } else if (formatter === 'iconBoolean') {
            this.isCompile = true;
            this.isIcon = true;

            const icon = {
                icon: value ? 'check' : 'times',
                color: value ? 'green' : 'red'
            } ;

            if (isNull(value) && column.formatterConfig?.nullAllowed) {
                icon.icon = 'ellipsis';
                icon.color = 'yellow';
            }

            this.result =  {
                icon,
                text: ''
            };

        } else if (formatter === 'compile') {
            this.isCompile = true;
            this.isIcon = this.result ? !!this.result.icon : false;

        } else if (formatter === 'duration') {
            const parsedValue = parseInt(value);

            if (!!isNumber(parsedValue)) {
                this.result = this.filter.duration(parsedValue);

            } else {
                this.result = DEFAULT_OUTPUT;

            }

        } else if (formatter === 'outputFilter') {
            this.result = this.filter.duration(this.result, column.formatterConfig.expression, column.formatterConfig.comparator);

        }

        if (!formatter && typeof this.result === 'string' && maxLength && this.result.length > maxLength) {
            this.fullResult = this.result;
            this.result = this.result.substr(0, maxLength) + '...';
        }

        this.checkCustomClass();

        this.cd.markForCheck();

    }

    getStateParams = (column: any) => {
        const data = column.state;

        this.isState = true;

        if (typeof data === 'function') {
            return column.state(this.row);

        } else {

          const stateData = this.stateParser.getPath({
            name: data.name,
            id: get(this.row, data.id),
            acl: data.acl
          });

          return { path: stateData[0], params: stateData[1] };
        }

    };

    checkCustomClass() {
      let className = '';

      /*
      [ngClass]="{
                                    'PreWrap': treePadding,
                                    'red' : cellMarker && cellMarker(row) === 'red',
                                    'green' : cellMarker && cellMarker(row) === 'green',
                                    'purple' : cellMarker && cellMarker(row) === 'purple',
                                    'grey' : cellMarker && cellMarker(row) === 'grey',
                                    'blue' : cellMarker && cellMarker(row) === 'blue',
                                    'yellow' : cellMarker && cellMarker(row) === 'yellow',
                                    'red-bg' : cellMarker && cellMarker(row) === 'red-bg',
                                    'green-bg' : cellMarker && cellMarker(row) === 'green-bg',
                                    'purple-bg' : cellMarker && cellMarker(row) === 'purple-bg',
                                    'grey-bg' : cellMarker && cellMarker(row) === 'grey-bg',
                                    'blue-bg' : cellMarker && cellMarker(row) === 'blue-bg',
                                    'yellow-bg' : cellMarker && cellMarker(row) === 'yellow-bg'
                                }"
       */

      if (this.treePadding) className += 'PreWrap ';

      const cellMarkerName = this.cellMarker && this.cellMarker(this.row) || '';

      if (cellMarkerName) className += `${cellMarkerName} `;

      this.customClass = className;
    }

}
// -------------- TABLE CELL DEFAULT --------------------

// -------------- TABLE CELL PARAMETERS -----------------
@Component({
    selector: 'pro-table-cell-parameters',
    template: `
        <div>
            <div *ngIf="!icon" [innerHTML]="result | safeHTML"></div>
            <span *ngIf="icon"><fa [name]="icon"></fa></span>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TableCellParametersComponent extends TableCellInstance implements OnInit {
    result: any;
    icon: string;

    constructor(
        private ParametersFormatter: TableParametersFormatterService,
        private Filter: FilterService,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    async ngOnInit() {
        let column = this.column;
        let row = this.row;
        let fieldValue: any = getFieldValue(column, row);
        let showName = true;
        let parametersArray = [];
        let groupTypeParams = [];
        let parameters;

        if (Array.isArray(fieldValue)) {
            if (!fieldValue.length) return this.result = DEFAULT_OUTPUT;

            parameters = fieldValue;

        } else {
            if (!fieldValue) return this.result = DEFAULT_OUTPUT;

            parameters = [fieldValue];
            showName = false;

        }

        for (let parameterData of parameters) {
            if (parameterData === null || parameterData.isVisible === false) continue;
            let orgParameter = parameterData && parameterData.parameter || parameterData;
            let override = parameterData;
            let type = orgParameter.type;
            let value = override.value;
            let parameterHtml = '<span class="TableCell-Parameter">';

            if (showName) parameterHtml += `<b>${orgParameter.name}</b>: `;

            if (type === 'material') {
                const dropdownOptions = await this.ParametersFormatter.loadMaterials();
                let result = dropdownOptions && dropdownOptions.filter((option: any) => option.id === Number(value))[0];

                parameterHtml += this.getOptionName(result);

            } else if (type === 'parameter_group') {
                groupTypeParams.push(parameterData);

                continue;

            } else if (type === 'material_category') {
                let materialCategories = await this.ParametersFormatter.loadMaterialCategories();
                let result = materialCategories && materialCategories.filter((option: any) => option.id === Number(value))[0];

                parameterHtml += this.getOptionName(result);

            } else if (type === 'dropdown') {
                const dropdownOptions = await this.ParametersFormatter.loadDropdownOptions();
                let result = dropdownOptions && dropdownOptions.filter((option: any) => option.id === Number(value))[0];

                parameterHtml += this.getOptionName(result);

            } else if (type === 'boolean') {
                let result = (value === true || value === 'true') ? 'yes'
                    : (value === false || value === 'false' || value === null) ? 'no'
                        : value;

                parameterHtml += this.Filter.translate(result);

            } else if (type === 'datetime') {

                if (value !== null && value) {
                    parameterHtml += this.Filter.dateTime(value);

                }

            } else if (type === 'duration') {
                if (value !== null && value) {
                    parameterHtml += this.Filter.duration(value);

                }

            } else if (type === 'price') {
                parameterHtml += value;

            } else if (type === 'list') {
                let array;

                parameterHtml += '<span style="word-break: initial; white-space: initial">'; // prevent list parameters taking big areas when white-space is not breaking

                try {
                    array = JSON.parse(value);
                } catch (e) {
                    array = value;
                }

                parameterHtml += array ? array.join(', ') : '';

                parameterHtml += '</span>';

            } else if (type === 'workgroup') {
                let item: any;

                try {
                    item = JSON.parse(value);
                } catch (e) {
                    item = value;
                }

                if (item.operationId) {
                    let operations = await this.ParametersFormatter.loadOperations();

                    parameterHtml += `<b>${this.Filter.translate('operation')}:</b> ${operations.filter((option: any) => option.id === Number(item.operationId))[0].name} ` ;
                }

                if (item.workplaceId) {
                    let workplaces = await this.ParametersFormatter.loadWorkplaces();

                    parameterHtml += `<b>${this.Filter.translate('workplace')}:</b> ${workplaces.filter((option: any) => option.id === Number(item.workplaceId))[0].name} ` ;
                }

            } else {
                parameterHtml += value !== null && value || '';

            }

            if (parameterData.icon) {
                this.icon = parameterData.icon;
            }

            parameterHtml += '</span>';
            parametersArray.push(parameterHtml);

        }

        parametersArray = parametersArray.concat(this.ParametersFormatter.getGroupTypeParams(groupTypeParams));
        this.result = parametersArray.join(' ');

        this.cd.markForCheck();

    }

    getOptionName(item: any) {
        return item ? item.name : DEFAULT_OUTPUT
    }
}
// -------------- TABLE CELL PARAMETERS -----------------


// -------------- TABLE CELL DIRECTIVE -----------------
@Component({
    selector: 'pro-table-cell-directive',
    template: '<ng-container #directive></ng-container>',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TableCellDirectiveComponent extends TableCellInstance implements OnInit {
    @ViewChild('directive', { read: ViewContainerRef, static: true }) directive: ViewContainerRef;
    private componentRef: ComponentRef<{}>;

    constructor(
      @Inject(TABLE_DIRECTIVE_COMPONENTS) private mappings: TableDirectiveComponentsMappings,
      private factoryResolver: ComponentFactoryResolver, private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        let directive = this.column.formatterConfig;
        let component: any;
        let column: any = this.column;

        switch (directive) {

            case 'pro-entity-table-status-switcher':
                component = EntityTableStatusSwitcherComponent;
                break;

            case 'pro-entity-table-color':
                component = EntityTableColorComponent;
                break;

            case 'pro-entity-table-checkbox':
                component = EntityTableCheckboxComponent;
                break;

            case 'pro-entity-table-image-with-upload':
                component = EntityTableImageWithUploadComponent;
                break;

            case 'pro-status-switch-checkbox':
                component = EntityTableStatusSwitchCheckboxComponent;
                break;

            case 'pro-entity-table-text-input':
                component = EntityTableTextInputComponent;
                break;

          default:
            if (this.mappings[directive]) component = this.mappings[directive];

        }

        if (component) {
            let factory = this.factoryResolver.resolveComponentFactory(component);
            this.componentRef = this.directive.createComponent(factory) as ComponentRef<{}>;

            let instance = <TableCellDirectiveComponent> this.componentRef.instance;
            instance.item = this.row;

            instance.config = {
                entity: column.entityName,
                key: column.key,
                getValue: column.getValue,
                disabled: column.isDisabled && column.isDisabled(this.row),
                config: column._config || {}
            }; // column.config is used by statsConfig
            instance.callback = { emit: (value: any) => { column.callback(this.row, value, column); } };

            if (column.additionalConfig) {
                Object.assign(instance.config, column.additionalConfig);
            }

            this.cd.markForCheck();

        } else {
          console.warn('Unknown table cell formatter directive: ', directive);
        }
    }

}
// -------------- TABLE CELL DIRECTIVE -----------------

// -------------- TABLE CELL ----------------------------
@Component({
    selector: 'pro-table-cell',
    template: `
        <ng-template #cell></ng-template>
    `
})

export class TableCellComponent implements OnInit, OnDestroy {
    @ViewChild('cell', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
    @Input() row: any;
    @Input() rowIndex: any;
    @Input() column: any;
    private componentRef: ComponentRef<{}>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) {

    }

    private mappings = {
        money: TableCellDefaultComponent,
        numeric: TableCellDefaultComponent,
        dateTime: TableCellDefaultComponent,
        parameters: TableCellParametersComponent,
        directive: TableCellDirectiveComponent,
        input: PromanTextSimpleComponent,
        image: PromanThumbnailComponent,
    };

    ngOnInit() {
        let row: any = this.row;
        let column: any = this.column;
        let instance: any;
        let factory: any;

        factory = this.componentFactoryResolver.resolveComponentFactory(this.mappings[column.formatter] || TableCellDefaultComponent);
        this.componentRef = this.container.createComponent(factory);

        if (column.formatter === 'input') {
            instance = <TableCellInstance> this.componentRef.instance;
            instance.value = getFieldValue(column, row);
            instance.config = Object.assign({}, this.column.formatterConfig || {});
            instance.onChange = { emit(value: any) { column.callback(row, value); } };

        } else {
            instance = <TableCellInstance> this.componentRef.instance;
            instance.row = row;
            instance.column = column;
            instance.file = getFieldValue(column, row);
            instance.config = Object.assign({}, this.column.formatterConfig || {});
            instance.rowIndex = this.rowIndex;

        }

    }

    ngOnDestroy() {

        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null as unknown as ComponentRef<{}>;

        }

    }

}
// -------------- TABLE CELL -----------------
