import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pro-entity-table-text-input',
  template: `
        <pro-text-simple [value]="value"
                         [config]="config"
                         (onChange)="handleChange($event)"
                         [disabled]="config.disabled"
                         proClickStopPropagation>
        </pro-text-simple>
    `
})

export class EntityTableTextInputComponent implements OnInit {
  @Input() item: any;
  @Input() config: {
    label?: string;
    key?: string;
    disabled?: boolean;
    parseNumber?: boolean;
    parseInteger?: boolean;
    type?: 'text'|'password'|'textarea'|'number';
    debounce?: number;
    prefix?: string;
    suffix?: string;
  };
  @Output() callback: EventEmitter<string|number> = new EventEmitter();
  value: string | number;

  constructor() {

  }

  ngOnInit() {
    this.value = this.item[this.config.key];
  }

  handleChange(value: string|number) {
    this.value = value;
    this.item[this.config.key] = value;

    this.callback.emit(value);
  }

}
