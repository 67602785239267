import { fromEvent, merge, Subject, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from '@proman/rxjs-common';
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NumberValidator } from '@proman/validators/number.validator';
import { RangeValidator } from '@proman/validators/range.validator';
import { InputErrorStateMatcher } from '@proman/validators/input-error-state-matcher';
import { UniqueValidatorService } from '@proman/services/unique-validator.service';
import { EmailValidator } from '@proman/validators/email.validator';
import { NoCommaValidator } from '@proman/validators/no-comma.validator';
import { StartsWithSymbolValidator } from '@proman/validators/starts-with-symbol.validator';
import { copyToClipboard, handleDecimalWithComma, isDefinedNotNull } from '@proman/utils';
import { EntityNameType } from '@proman/services/entity.service';
import { CustomAttacherTriggerService } from '@proman/overlay_cdk/attacher-trigger.service';
import { FocusOrigin } from '@angular/cdk/a11y';

const DEFAULT_DEBOUNCE_TIME = 1500;

@Component({
    selector: 'pm-txt',
    template:  `
        <mat-form-field [floatLabel]="config.floatLabel || 'auto'"
                        [ngClass]="{ 'important-input': config.important && (!isDefinedNotNull(value) || value===''), 'has-emoji': config.emoji }"
                        [color]="config.important && (!isDefinedNotNull(value) || value==='') ? 'warn' : 'primary'" [attr.data-name]="config.label"
                        #originElement>

            <ng-container [ngSwitch]="config.type">
                <span *ngSwitchCase="'textarea'" fxLayout="row">
                    <textarea *ngIf="!expandedTextArea && config.expandable"
                              #box
                              matInput
                              [ngStyle]="{ 'resize': 'none' }"
                              [placeholder]="config.label | translate"
                              (keydown.enter)="handleEnter($event)"
                              [value]="value"
                              [formControl]="control"
                              [required]="config.required"
                              [errorStateMatcher]="matchErrorState"
                    ></textarea>
                    <textarea matInput
                              #box
                              *ngIf="expandedTextArea || !config.expandable"
                              cdkTextareaAutosize
                              [placeholder]="config.label | translate"
                              (keydown.enter)="handleEnter($event)"
                              [value]="value"
                              [formControl]="control"
                              [required]="config.required"
                              [errorStateMatcher]="matchErrorState"
                    ></textarea>
                    <pro-btn *ngIf="config.expandable" icon="text-width" [theme]="expandedTextArea? 'accent' : 'grey'"
                            (onClick)="expandedTextArea = !expandedTextArea"></pro-btn>
                </span>

                <input *ngSwitchDefault
                       [type]="config.type || 'text'"
                       #box
                       matInput
                       [formControl]="control"
                       [placeholder]="config.label | translate"
                       [value]="value"
                       [errorStateMatcher]="matchErrorState"
                       [required]="config.required"
                       autocomplete="off"
                       [pmOverlay]="{ type: 'button', data: config.type === 'password' ? '' : value }"
                       [pmOverlayDebounce]="1500"
                >

            </ng-container>

            <span *ngIf="config.suffix" matSuffix>{{ config.suffix }}</span>
            <mat-error *ngIf="control && control.errors">
                <span *ngIf="control.errors.invalidEmail">{{ 'invalid_email_address' | translate }}</span>
                <span *ngIf="control.errors.isValidNumber">{{ 'invalid_number' | translate }}</span>
                <span *ngIf="control.errors.required">{{ 'field_is_required' | translate }}</span>
                <span *ngIf="control.errors.noComma">{{ 'comma_not_allowed' | translate }}</span>
                <span *ngIf="control.errors.startsWithLetter">{{ 'value_must_start_with_letter' | translate }}</span>
                <span *ngIf="control.errors.isValidRange">{{ 'invalid_range' | translate: control.errors }}</span>
                <span *ngIf="control.errors.isUnique">{{ 'value_occupied_suggestion' | translate:control.errors }}</span>
                <span *ngIf="control.errors.minlength">{{ 'required_min_length' | translate:control.errors.minlength }}</span>
                <span *ngIf="control.errors.maxlength">{{ 'required_max_length' | translate:control.errors.maxlength }}</span>
            </mat-error>
            <pro-btn *ngIf="config.emoji"
                     icon="face-smile"
                     theme="primary"
                     (onClick)="openEmojis()"
                     proClickStopPropagation></pro-btn>

        </mat-form-field>

        <ng-template #emojiTemplate>
            <div
                cdkMonitorSubtreeFocus
                (cdkFocusChange)="onEmojiFocusChange($event)"
                class="emoji-panel-content"
            >
                <pro-btn *ngFor="let smileysAndPeopleEmoji of this.smileysAndPeopleEmojiArr" class="emojiButton"
                         [label]="smileysAndPeopleEmoji"
                         (onClick)="copyEmoji(smileysAndPeopleEmoji)"
                >
                </pro-btn>
            </div>
        </ng-template>
    `
    ,
    styles: [`
        :host { display: inline-block; width: 100%; }
        mat-form-field { width: 100%; }
        input, textarea { width: 100%; }
        textarea { line-height: 1.4em; }

        .has-emoji input, .has-emoji textarea {
            padding-right: 32px;
        }

        pro-btn[icon="face-smile"] {
            position: absolute;
            right: 4px;
            bottom: 4px;
        }

        .emoji-panel-content {
            background: white;
            overflow-y: scroll;
            height: 300px;
            width: 530px;
            border: 3px solid #666;
            border-radius: 8px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TxtComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() config: {
        label?: string;
        validators?: {
            range?: { min?: number; max?: number };
            length?: { min?: number; max?: number };
            unique?: { resource: EntityNameType; field?: string; onlyWarning?: boolean; data?: unknown };
            noComma?: boolean;
            number?: boolean;
            email?: boolean;
            startsWithSymbol?: boolean;
        };
        parseNumber?: boolean;
        parseInteger?: boolean;
        type?: 'text'|'password'|'textarea'|'number';
        autofocus?: boolean;
        required?: boolean;
        important?: boolean;
        expandable?: boolean;
        debounce?: number;
        preventNewLine?: boolean;
        floatLabel?: 'always' | 'auto';
        prefix?: string;
        suffix?: string;
        emoji?: boolean;
    };
    @Input() value: string|number;
    @Input() disabled: any;
    @Input() control: UntypedFormControl;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('box') box: ElementRef;
    matchErrorState: any = new InputErrorStateMatcher();

    emittedValue: string;
    expandedTextArea: boolean;
    destroyed$: Subject<void> = new Subject<void>();

    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

    @ViewChild('originElement', { read: ElementRef, static: true })
    _originElement: ElementRef;

    @ViewChild('originElement', { read: ViewContainerRef, static: true })
    _viewContainerRef: ViewContainerRef;

    @ViewChild('emojiTemplate', )
    _template: TemplateRef<unknown>;

    smileysAndPeopleEmojiArr: string[] = ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩', '🥳', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '😣', '😖', '😫'
        , '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬', '🤯', '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔', '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '🤐', '🥴', '🤢', '🤮', '🤧', '😷', '🤒'
        , '🤕', '🤑', '🤠', '😈', '👿', '👹', '👺', '🤡', '💩', '👻', '💀', '☠️', '👽', '👾', '🤖', '🎃', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '👋', '🤚', '🖐', '✋', '🖖', '👌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍'
        , '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦵', '🦿', '🦶', '👂', '🦻', '👃', '🧠', '🦷', '👀', '👁', '👅', '👄', '🧵', '🧶', '👓', '🕶', '🥽', '🥼', '🦺', '👔', '👕', '👖', '🧣', '🧤', '🧥', '🧦', '👗'
        , '👘', '🥻', '🩱', '🩲', '🩳', '👙', '👚', '👛', '👜', '👝', '🎒', '👞', '👟', '🥾', '🥿', '👠', '👡', '🩰', '👢', '👑', '👒', '🎩', '🎓', '🧢', '⛑', '💄', '💍', '💼', '🎊', '🎉', '💋']


    constructor(
        private cd: ChangeDetectorRef,
        private UniqueValidatorService: UniqueValidatorService,
        private customAttacher: CustomAttacherTriggerService,

    ) {

    }

    ngOnInit() {

        if (this.config.type === 'number') this.config.type = 'text'; // fix to HTML clear number values with point;

        this.setControl();

    }

    isDefinedNotNull = (value: any) => isDefinedNotNull(value);

    setControl() {
        let config = this.config;
        let validators: any = [];
        let asyncValidator;
        if (!this.control) {

            // Set validators
            if (config.validators) {

                if (config.validators.range) validators.push(RangeValidator(config.validators.range));

                if (config.validators.number) validators.push(NumberValidator);

                if (config.validators.email) validators.push(EmailValidator);

                if (config.validators.noComma) validators.push(NoCommaValidator);

                if (config.validators.startsWithSymbol) validators.push(StartsWithSymbolValidator);

                if (config.validators.length) {
                    if (config.validators.length.min) validators.push(Validators.minLength(config.validators.length.min));
                    if (config.validators.length.max) validators.push(Validators.maxLength(config.validators.length.max));

                }

                if (config.validators.unique) {
                    let uniqueConfig = config.validators.unique;
                    asyncValidator = this.UniqueValidatorService.get(uniqueConfig.resource, uniqueConfig.field, uniqueConfig.onlyWarning, uniqueConfig.data);

                }

            }

            if (config.required) validators.push(Validators.required);

            this.control = new UntypedFormControl(isDefinedNotNull(this.value) ? this.value : '',  validators, asyncValidator);

        }

        if (this.disabled) this.control.disable();

        this.control?.statusChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.cd.markForCheck();
            });

    }

    mapEventData = (event: any) => {
        return event.target.value;
    };

    getEventObservable(eventName: string) {
        return fromEvent(this.box.nativeElement, eventName).pipe(
            map(this.mapEventData));
    }

    ngAfterViewInit() {

        const getDebounceTime = () => {
            let debounce = this.config.debounce;

            return (!isNaN(debounce)) ? debounce : DEFAULT_DEBOUNCE_TIME;
        };

        fromEvent(this.box.nativeElement, 'keydown')
            .pipe(
                filter((event: KeyboardEvent) => (event.code === 'Enter' && this.config.type !== 'textarea')),
                takeUntil(this.destroyed$),
            )
            .subscribe((event: any) => this.handleChange(event.target.value));

        merge(
                this.getEventObservable('blur'),
                this.control.valueChanges.pipe(debounceTime(getDebounceTime()))
            )
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.destroyed$),
            )
            .subscribe((value: string) => {
                if (value != this.value || (value === '' && this.value !== '')) {
                    if (this.control.pending) {
                        const Subscription = this.control.statusChanges.subscribe(() => {
                            this.handleChange(value);
                            Subscription.unsubscribe();
                        });

                    } else {
                        this.handleChange(value);

                    }

                }

            });

        fromEvent(this.box.nativeElement, 'blur')
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged())
            .subscribe(() => {
                this.handleBlur();
                this.onBlur.emit();
            });

        if (this.config.autofocus) setTimeout(() => this.box.nativeElement.focus());

        if (this.config.parseNumber || this.config.parseInteger) {
            fromEvent(this.box.nativeElement, 'change')
                .pipe(takeUntil(this.destroyed$))
                .subscribe((event: any) => this.handleParseNumber(event));

        }

        // update view on multiple line textarea
        this.cd.markForCheck();

    }

    ngOnChanges(changes: SimpleChanges) {
        const disabled = changes.disabled;
        const value = changes.value;

        if (this.control && disabled && disabled.currentValue !== disabled.previousValue) {
            disabled.currentValue ?
                this.control.disable() :
                this.control.enable();

        }

        if (isDefinedNotNull(this.value)) {
            if (typeof this.value === 'number') {
                this.value = (this.value as Number).toString();
            }
        } else {
            this.value = '';
        }

        if (value) {
            if (this.control && this.control.invalid) { //
                this.control.setValue(this.value);
            } if (this.control && !this.control.value) {
                this.control.setValue(value.currentValue, { emitEvent: false });
            }
        }

        this.cd.markForCheck();

    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    handleChange = (value: string) => {

        if (this.control.valid) {
            this.onChange.emit(value);
            this.emittedValue = value;

        }

    };

    handleParseNumber(event: any) {
        let value: string = event.target.value;

        value = handleDecimalWithComma(value) as string;

        if (this.config.parseInteger && value.includes('.')) this.control.setErrors({ isValidNumber: true });

        value = value.replace(/^0+(?!\.|$)/, ''); // remove zeros from start

        this.control.setValue(value, { emitEvent: true });

    }

    handleEnter(event: any) {
        if (this.config.preventNewLine) event.preventDefault();

    }

    handleBlur() {
        if (this.config.required) {
            this.control.setValue(this.emittedValue || this.value);

        }

        const trimValue = (emitValue: string): string => {
            // allow string / number comparison

            let result = emitValue;

            if (typeof result === 'string' && result !== ' ') {
                result = result.trim();
            }

            return result;
        };
        if (this.control.value !== trimValue(this.control.value) || this.control.value === '') {
            this.control.setValue(trimValue(this.control.value));
        }

    }

    openEmojis() {
            this.customAttacher.open(
                this._originElement,
                this._viewContainerRef,
                this._template,
                {  }
            );

    }

    onEmojiFocusChange(focus: FocusOrigin) {
        if (!focus) {
            this.customAttacher.dispose();
        }
    }

    copyEmoji(emoji: string) {
        this.control.setValue(this.control.getRawValue() + emoji);
        this.customAttacher.dispose();
    }

}
