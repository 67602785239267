import { Component, Input, OnInit } from '@angular/core';
import { ImagePathService } from '../../services/image-path.service';
import { Entity } from '../../services/entity.service';

const DEFAULT_OPTIONS = { isRotate: true };

@Component({
    selector: 'pro-preview',
    template: `
        <div class="Preview"
             fxLayout="column">
                <span>
                    {{ image.name }}</span>
            <img class="Preview-img"
                 [src]="path">
        </div>
    `,
    styles: [
        ':host { height: 100% }',
        '.Preview-img { max-width:100%; max-height:calc(100% - 64px); object-fit: contain; }'
    ]
})

export class PreviewComponent implements OnInit {
    @Input() image: any;
    @Input() settings: any;

    fileEntity: any;
    options: any;
    path: any;

    constructor(
        private ImagePath: ImagePathService,
        private Entity: Entity
    ) {
        this.fileEntity = Entity.get('file');
    }

    ngOnInit() {

        this.options = Object.assign({}, DEFAULT_OPTIONS, this.settings || {});

        if (this.image.data) {
            this.path = this.image.data;

        } else {
            this.path = this.ImagePath.getFile(this.image, 'pdf');

        }

    }
}
