import { Injectable } from '@angular/core';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { isDefined } from '@proman/utils';

const LOGGER_KEY = 'LOGGER';

@Injectable({ providedIn: 'root' })
export class LoggerService {
    isLogger: boolean;

    constructor(
        private LocalStorage: LocalStorageService,
    ) {
        this.isLogger = isDefined(this.LocalStorage.get(LOGGER_KEY))
    }

    log(...data: any[]) {
        if (this.isLogger) console.log('LOGGER', ...data);
    }

}
