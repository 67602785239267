import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { GlobalOverlayModule } from '@proman/overlay/global-overlay.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';

const COLORS = [
    ['f3f3f3', 'f6faed', 'f1fbfa', 'f4f9fd', 'fcf5f9', 'fdf8dd', 'fbf6e8'],
    ['e6e6e6', 'e9f4d5', 'dff6f3', 'e4f1fa', 'f8e7f1', 'fbedaa', 'f5e9c7'],
    ['bababa', 'c0df8f', 'a5dfd5', 'b3dff8', 'f6cce8', 'f7d66c', 'dbc285'],
    ['878787', '8cb953', '5fbbb2', '5fa3d8', 'ed85bf', 'ec7844', 'bf6a31'],
    ['4d4d4d', '589131', '51958e', '3374da', 'c83380', 'cf2e28', '845221'],
    ['1a1a1a', '386225', '2d635d', '235299', '821e50', '802416', '4f3210'],
];

@Component({
    selector: 'pro-color',
    standalone: true,
    imports: [
        CommonModule,
        Fa6Module,
        PipesModule,
        GlobalOverlayModule,
        FlexLayoutModule,
        SharedDirectivesModule,
    ],
    template: `
        <div class="Color Padding"
             [ngClass]="{ 'Editable': !(noPadding || config?.noPadding) }"
             fxLayout="row"
             fxLayoutAlign="start center">
            <div class="Color-input"
                 [style.background]="getStyleValue()"
                 [ngStyle]="{ 'margin-right': noPadding ? '0' : '10px' }"
                 (click)="openDialog($event)"></div>
            <div class="Editable-Label" *ngIf="!noLabel">
                {{ (config?.label || 'color') | translate }}
            </div>
        </div>

        <div class="ColorPickerOverlay" *ngIf="isVisible" (click)="isVisible = false;" #container>
            <div class="ColorPickerContainer" [ngStyle]="{ left: left, top: top }" (click)="$event.stopPropagation();">
                <div fxLayout="row" *ngFor="let colorRow of colors">
                    <div class="ColorPickerItem"
                         *ngFor="let color of colorRow"
                         [ngStyle]="{ background: '#' + color }"
                         [proContrastingColor]="color"
                         (click)="handleClick(color)"
                    >a</div>
                </div>

                <div fxLayout="row">
                    <div class="ColorPickerWhite" style="background: #ffffff" (click)="handleClick('ffffff')">a</div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .Color {
            padding-left: 4px;
        }
        .Color-input {
            width: 20px;
            height:20px;
            border-radius: 5px;
            border: 1px solid #464646;
            cursor: pointer;
        }

        .ColorPickerContainer {
            background: rgb(255,255,255);
            padding: 5px;
            border-radius: 5px;
        }

        .ColorPickerOverlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*1000 is dialog overlay index*/
            z-index: 1001;
            background: rgba(0, 0, 0, 0.25);
        }

        .ColorPickerContainer {
            position: absolute;
            z-index: 9999;
        }

        .ColorPickerItem {
            width: 30px;
            height: 30px;
            border-radius: 2px;
            margin: 2px;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
        }

        .ColorPickerWhite {
            border-radius: 2px;
            margin: 2px;
            border: 1px solid black;
            height: 30px;
            width:100%;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
        }

    `]
})

export class PromanColorComponent implements OnInit, OnDestroy {
    @Input() value: any;
    @Input() noPadding: boolean;
    @Input() noLabel: boolean;
    @Input() disabled: boolean;
    @Input() config: any = {};
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @ViewChild('container') container: ElementRef;
    colors: any = COLORS;
    isVisible: boolean = false;
    left: string;
    top: string;

    constructor() {}

    ngOnInit() {
        this.value = this.value ? '#' + this.value : '#ffffff';
    }

    ngOnDestroy() {
        this.isVisible = false;

        setTimeout(() => {
            const body = document.querySelector('body');
            const overlay = document.querySelector('.ColorPickerOverlay');

            if (body && overlay) body.removeChild(overlay);

        });
    }

    getStyleValue() {
        return this.value ? (this.value[0] === '#') ? this.value : ('#' + this.value) : '#ffffff';
    }

    openDialog($event: MouseEvent) {
        if (!this.onChange.observers.length || this.disabled) return;

        this.isVisible = true;

        let x: number = $event.clientX;
        let y: number = $event.clientY;

        const windowWidth: number = window.innerWidth;
        const windowHeight: number = window.innerHeight;

        if ((x + 250) > windowWidth) {
            x = windowWidth - 250;
        }

        if ((y + 250) > windowHeight) {
            y = windowHeight - 250;
        }

        this.left = `${x}px`;
        this.top = `${y}px`;

        setTimeout(() => {
            const body = document.querySelector('body');
            const overlay = document.querySelector('.ColorPickerOverlay');

            if (body && overlay) body.appendChild(overlay);

        });
    }

    handleClick(color: any) {
        this.value = color;
        this.onChange.emit(color);
        this.isVisible = false;
    }

}
