import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { ModelService } from '@proman/services/model.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { getIndexByProperty, mapId, get, getRandomString, isDefinedNotNull } from '@proman/utils';
import * as $ from 'jquery';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

export interface OnDropEmitType<T> {
  position: number;
  item: T;
}

export interface OnEditEmitType<T> {
  item: T;
  event: MouseEvent;
}

export interface OnRemoveEmitType<T> {
  item: T;
  index: number;
}

@Component({
  selector: 'pro-list-manager',
  template: `
        <div class="ListManager"
             fxLayout="column" >
            <div *ngIf="config" class="List">
                <div class="List-header"
                     *ngIf="!config.noAdd || config.label"
                     fxLayout="row"
                     fxLayoutAlign="start center"
                     [ngClass]="{ 'search': isSearch }">
                    <div class="horizontal-slide"
                         fxLayout="row"
                         fxLayoutAlign="start center"
                         *ngIf="!isSearch">
                        <pro-label>{{ config.label | translate }}</pro-label>
                        <pro-btn
                                *ngIf="!config.noAdd"
                                (click)="handleAddClick($event)"
                                icon="plus"
                                theme="accent"
                                [tooltipPosition]="'right'"
                                [tooltip]="'add'| translate"
                        ></pro-btn>
                    </div>

                    <div class="horizontal-slide"
                         fxLayout="row"
                         fxLayoutAlign="start center"
                         *ngIf="isSearch">
                      <pro-select [value]="test"
                                  fxFlex.gt-md="200px"
                                  [config]="{ label: config.searchEntity, autoopen: true }"
                                  [options]="searchOptions"
                                  (onChange)="handleAdd($event)"></pro-select>
                      <pro-btn (click)="unsetSearch()"
                               [label]="'cancel' | translate"></pro-btn>
                    </div>

                    <div fxFlex></div>

                    <ng-content select=".ListManager-ActionButtons"></ng-content>

                </div>
                <div fxLayout="column"
                     *ngIf="!items?.length">
                    <hr>
                    <pro-no-records></pro-no-records>
                </div>
                <div *ngIf="isDraggable"
                     [dragula]="dragulaId"
                     [dragulaModel]="items"
                     proScrollLimit [(scrollLimit)]="viewLimit">
                          <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>

                </div>

                <div *ngIf="!isDraggable"
                     proScrollLimit
                     [(scrollLimit)]="viewLimit"
                     [scrollLimitIncrement]="10"
                    >
                <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
                </div>
            </div>
        </div>

        <ng-template #itemsTemplate>
        <div class="List-row ListManager__item"
         fxLayout="column"
         *ngFor="let item of items;let $index = index;"
         (click)="handleRowClick(item, $event)"
         [attr.data-list-item]="getName(item)"
         [ngClass]="{ NoPadding: config.noPadding }"

         >
            <ng-container *ngIf="$index <= viewLimit">
          <div fxLayout="row"
             fxLayoutAlign="start center">

            <pro-move-handle *ngIf="isDraggable" [class]="'List-manager-moveHandle'"></pro-move-handle>
            <pro-checkbox *ngIf="onCheck.observers.length" [value]="item[config.checkProperty]" [config]="{}" (onChange)="handleCheck(item, $event)"></pro-checkbox>

            <div *ngIf="!config.template"
                fxLayout="column">
                <div class="ListManager__itemAlias">{{ getAlias(item) }}</div>
                <div class="ListManager__itemName" [ngClass]="{ 'List-row--extra': getAlias(item) }" fxLayout="row" fxLayoutAlign="start center">
                    <pro-thumbnail *ngIf="config.image"
                                  [file]="item[config.image]"
                                  size="32"
                                  class="RightMargin"
                                  >
                    </pro-thumbnail>
                    <ng-container>{{ getName(item) }}</ng-container>
                </div>
            </div>

              <pro-dynamic-template *ngIf="config.template"
                                    [type]="config.template"
                                    [item]="item"
                                    [config]="config"
                                    [actions]="actions"
                                    fxFlex></pro-dynamic-template>

            <div *ngIf="!config.template" fxFlex></div>
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxFlex="0 0 auto">
                <pro-btn *ngIf="isSelectable"
                        icon="check"
                        [theme]="item._isSelected ? 'accent' : 'grey'"
                        (onClick)="handleSelect($event, $index, item)"
                        [tooltip]="item._isSelected ? 'It_is_default_supplier' : 'Choose_as_default_supplier'| translate"></pro-btn>
                <pro-btn *ngIf="isEye"
                        icon="eye"
                        theme="accent"
                        (onClick)="$event.stopPropagation();onEye.emit({ item: item })"
                        tooltip="show_order"
                        ></pro-btn>
                <pro-btn *ngIf="isEdit"
                        icon="edit"
                        theme="accent"
                        tooltip="edit"></pro-btn>
                <pro-btn *ngIf="isBarcode"
                        icon="barcode"
                        (onClick)="handleBarcode(item, $event)"
                        theme="accent"
                        tooltip="barcode"></pro-btn>
<!--                <pro-btn *ngIf="config.eventLogEntity"-->
<!--                        icon="list"-->
<!--                        class="ListManager__eventLogButton"-->
<!--                        (onClick)="handleEventLog(item, $event)"-->
<!--                        theme="accent"-->
<!--                        tooltip="log"></pro-btn>-->
                <pro-btn *ngIf="onQr.observers.length"
                        icon="qrcode"
                        theme="accent"
                        (onClick)="$event.stopPropagation();onQr.emit({ item: item })"
                        tooltip="create_new_qr_code"></pro-btn>
                <pro-btn *ngIf="onLink.observers.length"
                        icon="chevron-double-right"
                        theme="accent"
                        tooltip="go_to {{getName(item)}}"
                        (onClick)="$event.stopPropagation();onLink.emit({ item: item, event: $event })"></pro-btn>
                <pro-btn *ngIf="isRemovable"
                        (onClick)="handleRemove($event, $index, item)"
                        [icon]="config.trashIcon ? 'trash' : 'times'"
                        theme="warn"
                        tooltip="remove"></pro-btn>

            </div>

        </div>
            </ng-container>
    </div>
</ng-template>
    `,
  styles: [
    '.List-header { overflow: hidden; position: relative; height: 48px; }',
    '.horizontal-slide { margin-top: 6px; }',
    `.NoPadding { padding: 0; }`
  ]
})

export class ListManagerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() items: any[];
  @Input() config: Partial<{
    key: string;
    mainEntityName: EntityNameType;
    mainEntity: any;
    searchEntity: EntityNameType;
    searchParams: any;
    searchGetOptionName: (item: any) => string;
    searchSearchFields: string[];
    handleSearch: (query: string) => Promise<any[]>;
    noSplice: boolean;
    preventHandling: boolean;
    silentRemove: boolean;
    preventRowEdit: boolean;
    eventLogEntity: string;
    namePath: string;
    aliasPath: string;
    label: string;
    noAdd: boolean;
    trashIcon: boolean;
    template: string;
    isRemoveAll: boolean;
    noPadding: boolean;
    image: string;
    state: string;
    setup: any; // for dynamic template component adjustments
    selectableTooltip: string;
    checkProperty: string;
  }> = {};
  @Input() actions: any[];
  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<OnEditEmitType<any>> = new EventEmitter<OnEditEmitType<any>>();
  @Output() onCheck: EventEmitter<any> = new EventEmitter();
  @Output() onDrop: EventEmitter<OnDropEmitType<any>> = new EventEmitter<OnDropEmitType<any>>();
  @Output() onBarcode: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<OnRemoveEmitType<any>> = new EventEmitter<OnRemoveEmitType<any>>();
  @Output() onEye: EventEmitter<any> = new EventEmitter();
  @Output() onQr: EventEmitter<any> = new EventEmitter();
  @Output() onLink: EventEmitter<OnEditEmitType<any>> = new EventEmitter<OnEditEmitType<any>>();
  @Output() onSelectedChange: EventEmitter<any> = new EventEmitter();
  isEdit: boolean;
  isDraggable: boolean;
  isRemovable: boolean;
  isSelectable: boolean;
  isSearch: boolean;
  isBarcode: boolean;
  isEye: boolean;
  dragulaId: any;
  dragItem: any;
  isMovable: boolean;
  subscriberDrag: any;
  subscriberDrop: any;
  itemsList: any;
  mainEntity: any;
  model: any;
  searchOptions: any;
  viewLimit: number = 100;

  constructor(
    private Entity: Entity,
    private Model: ModelService,
    private QueryExpression: QueryExpressionService,
    private Dragula: DragulaService,
    private Dialog: MatLegacyDialog,
  ) {

  }

  ngOnInit() {
    this.isEdit = Boolean(this.onEdit.observers.length);
    this.isEye = Boolean(this.onEye.observers.length);
    this.isDraggable = Boolean(this.onDrop.observers.length);
    this.isSelectable = Boolean(this.onSelectedChange.observers.length);
    this.isBarcode = Boolean(this.onBarcode.observers.length);
    this.isRemovable = Boolean(this.onRemove.observers.length) || this.config.silentRemove;

    if (this.config.mainEntity) {
      this.mainEntity = this.config.mainEntity;
      this.model = this.Model.get(this.mainEntity, this.Entity.get({ name: this.config.mainEntityName }));

    }

    if (typeof this.items === 'undefined' && this.mainEntity) {
      this.items = this.mainEntity[this.config.key];

    }

    if (this.isDraggable) this.initDragula();

    if (this.config.mainEntity) this.handleSearch('');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items?.length > 0) {
      this.getItemsList();
    }
  }

  ngOnDestroy() {
    if (this.isDraggable) {
      this.Dragula.destroy(this.dragulaId);
      this.subscriberDrag.unsubscribe();
      this.subscriberDrop.unsubscribe();
    }
  }

  getAlias(item: any) {
    return get(item, this.config.aliasPath || 'alias');
  }

  getName(item: any) {
    return get(item, this.config.namePath || 'name');
  }

  initDragula() {
    this.dragulaId = `list-manager2-${new Date().valueOf()}-${getRandomString()}`;

    this.getItemsList();

    this.Dragula.createGroup(this.dragulaId, {
      moves:  (el: any, source: any, handle: Element) => {

      const isMove = (element: Element) => element.classList.contains('List-manager-moveHandle');

      this.isMovable = isMove(handle) || isMove(handle?.parentElement) || isMove(handle?.parentElement?.parentElement);

      return this.isMovable;
      }
    });

    this.subscriberDrag = this.Dragula.drag(this.dragulaId).subscribe(({ name, el, source }) => {

      if (this.isMovable) {
        let index = [].slice.call(el.parentElement.children).indexOf(el);

        this.dragItem = Object.assign({}, this.itemsList[index]);

      }

    });

    this.subscriberDrop = this.Dragula.drop(this.dragulaId).subscribe(async (value: any) => {
      if (this.isMovable) {
        let el = value.el;
        let index = [].slice.call(el.parentElement.children).indexOf(el);
        let swapItem = this.items[index];

        setTimeout(() => Promise.resolve());

        let position = getIndexByProperty(this.itemsList, 'id', swapItem.id);

        this.onDrop.emit({ item: this.dragItem, position });

        this.dragItem = null;
        this.getItemsList();

      }

    });

  }

  getItemsList = () => {
    this.itemsList = [];

    this.items?.forEach((item: any) => this.itemsList .push(item));
  };

  handleAddClick($event: any) {
    if (this.config && isDefinedNotNull(this.config.handleSearch)) this.config.handleSearch('').then((response) => this.searchOptions = response);

    if (this.onAdd.observers.length && !this.config.preventHandling) {
      this.onAdd.emit($event);

    } else {
      this.isSearch = true;

    }

  }

  unsetSearch() {
    this.isSearch = false;
  }

  handleAdd(value: any) {
    this.unsetSearch();

    if (this.config.preventHandling) {
      if (this.mainEntity && this.config.key) {
        if (this.isSelectable && !this.items.length) {
          value._isSelected = true;
        }
        this.mainEntity[this.config.key].push(value);

      }

      this.onAdd.emit(value);
      this.searchOptions = this.handleSearch('');

    } else {
      this.model
        .addAssociation(this.config.key, value)
        .then(() => {
          this.mainEntity[this.config.key].push(value);
          this.onAdd.emit(value);
          this.searchOptions = this.handleSearch('');
        });

    }

  }

  handleSearch(query: string) {
    const usedIds = this.mainEntity ?
      this.mainEntity[this.config.key]?.map((item: any) => item.id) :
      this.items.length ?
        this.items.map(mapId) :
        [];
    let data: any = { };

    if (this.config.searchSearchFields) {
      data.search = {};
      this.config.searchSearchFields.forEach((field: string) => data.search[field] = query);

    } else {
      data.name = query;

    }

    if (usedIds?.length) {
      data.id = this.QueryExpression.notIn(usedIds);
    }

    if (this.config.searchParams) {
      Object.assign(data, this.config.searchParams);

    }

    if (this.config.handleSearch) {
      this.searchOptions = this.config.handleSearch(query)
        .then((response: any[]) => {
          if (this.config.searchGetOptionName) {
            response.forEach((item: any) => item.name = this.config.searchGetOptionName(item))
          }

          return response
        });

    } else {
      this.Entity
        .get({ name: this.config.searchEntity })
        .search(data)
        .then((response: any[]) => {
          if (this.config.searchGetOptionName) {
            response.forEach((item: any) => item.name = this.config.searchGetOptionName(item))
          }

          this.searchOptions =  response;
        });
    }

  }

  handleRemove($event: any, index: number, item: any) {
    $event.stopPropagation();

    if (this.onRemove.observers.length) {
      this.onRemove.emit({ index, item });
      if (!this.config.noSplice) {
        this.items.splice(index, 1);

      }

    } else if (this.config.silentRemove) {
      this.model
        .removeAssociation(this.config.key, item)
        .then(() => {
          this.mainEntity[this.config.key].splice(index, 1);
          this.onAdd.emit();
        });

    }

  }

  handleSelect($event: any, index: number, item: any) {
    $event.stopPropagation();

    this.items.forEach((_item: any) => _item._isSelected = false);

    item._isSelected = true;

    this.onSelectedChange.emit({ index, item });
  }

  handleRowClick(item: any, event: MouseEvent) {
    if (!this.config.preventRowEdit) this.onEdit.emit({ item, event });
  }

  handleCheck(item: any, value: boolean) {
    this.onCheck.emit({ item, value });
  }

  // handleEventLog(item: any, event: MouseEvent) {
  //   event.stopPropagation();
  //
  //   this.Dialog
  //     .open(ActivityLogDialogComponent,
  //       { entityName: this.config.eventLogEntity, id: item.id },
  //       { width: '650px' });
  // }

  handleBarcode(item: any, event: MouseEvent) {
    event.stopPropagation();

    this.onBarcode.emit(item);
  }

}
